import { useTranslation } from "react-i18next";
import { Outlet, useMatch } from "react-router-dom";

import { useBreakpoint } from "@hooks/use-breakpoint";

import { CategoryList } from "@/features/services/components/categories/CategoryList";

export const CategoriesView = () => {
  const { t } = useTranslation();
  const { isLg } = useBreakpoint("lg");
  const isMatch = useMatch("/services/:headCategoryUuid");

  return (
    <>
      {(isLg || isMatch) && <CategoryList />}
      {isMatch && isLg && (
        <div className="flex w-full justify-center py-10 text-stone-500">
          {t("services.categories.selectCTA")}
        </div>
      )}
      <Outlet />
    </>
  );
};
