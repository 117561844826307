import { useMemo, useState } from "react";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Disclosure, Transition } from "@headlessui/react";

import { CheckCircleIcon, ExclamationTriangleIcon, PlusIcon } from "@heroicons/react/24/outline";

import { Alert } from "@components/ui/Alert";
import { Button } from "@components/ui/Button";
import { TextAreaField } from "@components/ui/TextAreaField";

import { useToasts } from "@providers/ToastsProvider";

import { useSalonSmsTemplateStore } from "@features/messages/hooks";
import { useSessionStore } from "@features/session/hooks";

import { useFlag } from "@/features/feature-flags/providers/FlagsContext";
import { AnimatedExpandIcon } from "@/icons/AnimatedExpandIcon";
import { SalonSmsTemplate, UpdateSmsTemplateInput } from "@/types";
import { isNotWhitespace } from "@/utils/form";

import { countCharacters } from "../utils/countCharacters";
import { SMSCharacterCounter } from "./SMSCharacterCounter";

export type Variable = {
  name: string;
  value: string;
  example: string;
};

type SMSTemplateFormProps = {
  template: SalonSmsTemplate;
  variables: Variable[];
};

export const SMSTemplateForm = ({ template, variables }: SMSTemplateFormProps) => {
  const { t } = useTranslation();
  const [showPlanAlert, setShowPlanAlert] = useState<boolean>(false);
  const { isFreePlan } = useSessionStore();
  const { updateTemplate } = useSalonSmsTemplateStore();
  const { showToast } = useToasts();
  const { enabled: paymentsEnabled } = useFlag("in_app_payments");

  const {
    register,
    formState: { isValid },
    setValue,
    control,
    handleSubmit,
  } = useForm<UpdateSmsTemplateInput>({
    mode: "all",
    shouldUnregister: true,
    defaultValues: {
      description: template.description || "",
    },
  });

  const descriptionField = useWatch({ name: "description", control });

  const messageCharacters = useMemo(() => countCharacters(descriptionField), [descriptionField]);

  const appendInput = (value: string) => {
    setValue("description", [descriptionField && descriptionField, `{${value}}`].join(" ").trim());
  };

  const handleUpdateTemplate: SubmitHandler<UpdateSmsTemplateInput> = async (data) => {
    if (isFreePlan) {
      setShowPlanAlert(true);
    } else {
      const { errors } = await updateTemplate({
        description: data.description,
        title: template.title,
        templateUuid: template.uuid,
      });

      !errors &&
        showToast({
          title: t("generic.changesSaved"),
        });
    }
  };

  return (
    <Disclosure>
      {({ open }) => (
        <>
          <Disclosure.Button className="flex w-full items-center gap-2 rounded-md bg-stone-100 py-2 text-stone-500">
            <AnimatedExpandIcon open={open} />
            <h2>{template.title}</h2>
            {messageCharacters.messages > 1 && (
              <ExclamationTriangleIcon className="h-5 w-5 text-gold-600" />
            )}
          </Disclosure.Button>
          <Disclosure.Panel className="grid gap-4 py-4 md:grid-cols-[3fr,_2fr]">
            <div className="grid h-fit gap-4">
              <div className="flex h-fit flex-wrap gap-2">
                {variables.map((variable) => (
                  <Button
                    key={variable.value}
                    variant="secondary-outline"
                    size="tiny"
                    startIcon={<PlusIcon />}
                    onClick={() => appendInput(variable.value)}>
                    {variable.name}
                  </Button>
                ))}
              </div>
              <form noValidate className="grid gap-4" onSubmit={handleSubmit(handleUpdateTemplate)}>
                <div>
                  <TextAreaField
                    {...register("description", {
                      validate: {
                        isNotWhitespace,
                      },
                    })}
                    label={t("messages.messageContent")}
                    className="block w-full rounded-md border-stone-300 shadow-sm focus:border-gold-500 focus:ring-gold-500 sm:text-sm"
                  />
                  <SMSCharacterCounter messageCharacters={messageCharacters} />
                </div>
                <Transition
                  show={showPlanAlert && paymentsEnabled}
                  appear
                  as="div"
                  enter="transition ease-out duration-200"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-100"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95">
                  {showPlanAlert && (
                    <Alert
                      type="warning"
                      title={t("subscriptions.featureUnavailableTitle")}
                      description={t("subscriptions.featureUnavailableDescription")}>
                      <Button
                        to="/subscriptions"
                        variant="neutral"
                        className="bg-green-50 px-4 py-1 text-green-500 transition-colors hover:bg-green-100 hover:text-green-600">
                        {t("subscriptions.upgrade")}
                      </Button>
                    </Alert>
                  )}
                </Transition>
                <Button
                  type="submit"
                  className="md:w-1/4"
                  startIcon={<CheckCircleIcon />}
                  disabled={showPlanAlert || !isValid || messageCharacters.length === 0}>
                  {t("generic.save")}
                </Button>
              </form>
            </div>
            <div className="h-fit rounded-sm bg-stone-50">
              <h3 className="text-xs font-medium uppercase text-stone-500">
                {t("messages.templates.exampleMessage")}
              </h3>
              <p className="break-all text-stone-500">{descriptionField}</p>
              {messageCharacters.messages > 1 && messageCharacters.shortenByChars.length >= 1 && (
                <div className="flex items-center gap-2 pt-4 text-gold-600">
                  <ExclamationTriangleIcon className="mt-1 h-4 w-4 shrink-0 self-start" />
                  <p className="text-sm">
                    {t("messages.templates.shortenedMessageBy", {
                      messagePartsAmount: messageCharacters.messages,
                    })}
                    {messageCharacters.shortenByChars.map((char, index) =>
                      messageCharacters.shortenByChars.length - 1 === index
                        ? ` ${char}`
                        : ` ${char},`,
                    )}
                    .
                  </p>
                </div>
              )}
              {messageCharacters.messages > 1 && messageCharacters.shortenByChars.length === 0 && (
                <div className="flex items-center gap-2 pt-4 text-gold-600">
                  <ExclamationTriangleIcon className="mt-1 h-4 w-4 self-start" />
                  <p className="text-sm">
                    {t("messages.templates.messagePartsAmount", {
                      messagePartsAmount: messageCharacters.messages,
                    })}
                  </p>
                </div>
              )}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};
