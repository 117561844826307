import { Breadcrumbs } from "@components/layout/header/Breadcrumbs";

import { useBreakpoint } from "@hooks/use-breakpoint";

import { TopUpSmsAction } from "@features/payments/components/TopUpSmsAction";

import { cn } from "@/utils/utils";

export const Header = () => {
  const { isMd } = useBreakpoint("md");

  return isMd ? (
    <header
      className={cn(
        "sticky top-0 z-30 flex h-full items-center justify-between bg-gold-100 pl-2 pr-0 pt-0 sm:border-b-0 sm:py-4 sm:pl-4 md:p-0",
        "before:absolute before:-bottom-4 before:left-0 before:size-4 before:bg-gold-100 before:content-['']",
        "after:absolute after:-bottom-4 after:left-0 after:size-4 after:rounded-tl-full after:bg-gold-50 after:content-['']",
      )}>
      <div className="flex w-full items-center justify-between px-4 py-2">
        <Breadcrumbs />
        <TopUpSmsAction />
      </div>
    </header>
  ) : null;
};
