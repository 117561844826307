import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { ChatBubbleLeftEllipsisIcon, PlusIcon } from "@heroicons/react/24/outline";

import { Subheader } from "@components/layout/subheader/Subheader";
import { Button } from "@components/ui/Button";

import { bindDialogState, useDialog } from "@hooks/use-dialog";

import {
  ClientFormDialog,
  ClientFormDialogStateProps,
} from "@/features/clients/components/ClientFormDialog";
import { useCountersStore, useSessionStore } from "@/features/session/hooks";

import { useClientsContext } from "../providers/ClientsProvider";
import { ClientBulkSMSDialog } from "./ClientBulkSMSDialog";

export const ClientListSubheader = () => {
  const { t } = useTranslation();
  const addClientDialog = useDialog<ClientFormDialogStateProps>();
  const { selectedClients } = useClientsContext();
  const { smsLimit } = useCountersStore();
  const { permissions } = useSessionStore();
  const [open, setOpen] = useState(false);

  const isSendButtonEnabled = useMemo(() => {
    const selected = Object.values(selectedClients).filter((isSelected) => isSelected);

    return selected.length > 0 && smsLimit > 0 && smsLimit >= selected.length;
  }, [selectedClients, smsLimit]);

  return (
    <div className="mb-2 sm:mb-8">
      <Subheader
        title={t("clients.clients")}
        leftSlot={
          <div className="inline-flex gap-4">
            <Button
              startIcon={<PlusIcon />}
              size="small"
              disabled={!permissions.add_client}
              onClick={() => addClientDialog.open()}>
              {t("clients.addNew")}
            </Button>
            <Button
              startIcon={<ChatBubbleLeftEllipsisIcon />}
              size="small"
              variant="primary-outline"
              disabled={!isSendButtonEnabled || !permissions.send_bulk_sms}
              onClick={() => setOpen(true)}>
              {t("actions.sendSms")}
            </Button>
          </div>
        }
      />
      <ClientFormDialog {...bindDialogState(addClientDialog)} title={t("clients.addNew")} />
      <ClientBulkSMSDialog open={open} setOpen={setOpen} />
    </div>
  );
};
