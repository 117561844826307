import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import useBreadcrumbs from "use-react-router-breadcrumbs";

import { CategoryBreadcrumb } from "@features/services/components/categories/CategoryBreadcrumb";
import { HeadCategoryBreadcrumb } from "@features/services/components/headCategories/HeadCategoryBreadcrumb";

import { ClientDetailsBreadcrumb } from "@/views/clients/ClientDetailsView";
import { EmployeeDetailsBreadcrumb } from "@/views/employees/EmployeeDetailsView";

export const Breadcrumbs = () => {
  const { t } = useTranslation();

  const routes = [
    {
      path: "/",
      breadcrumb: t("generic.home"),
    },
    {
      path: "billing-info",
      breadcrumb: t("payments.heading"),
    },
    {
      path: "statistics",
      breadcrumb: t("sidebar.navigation.appointments.children.statistics"),
    },
    {
      path: "subscriptions",
      breadcrumb: t("subscriptions.title"),
    },
    {
      path: "calendar",
      breadcrumb: t("sidebar.navigation.appointments.children.calendar"),
    },
    {
      path: "settings",
      breadcrumb: t("settings.title"),
    },
    {
      path: "clients",
      breadcrumb: t("sidebar.navigation.appointments.children.clients"),
      children: [
        {
          path: ":id",
          breadcrumb: ClientDetailsBreadcrumb,
        },
        {
          path: ":id/edit",
          breadcrumb: t("generic.edit"),
        },
      ],
    },
    {
      path: "salon",
      breadcrumb: t("sidebar.navigation.mySalon.children.salonProfile"),
      children: [
        {
          path: "edit",
          breadcrumb: t("salonProfile.edit"),
        },
      ],
    },
    {
      path: "employees",
      breadcrumb: t("sidebar.navigation.mySalon.children.employees"),
      children: [
        {
          path: ":id",
          breadcrumb: EmployeeDetailsBreadcrumb,
        },
        {
          path: ":id/edit",
          breadcrumb: t("generic.edit"),
        },
      ],
    },
    {
      path: "opening-hours",
      breadcrumb: t("sidebar.navigation.mySalon.children.openingHours"),
      children: [
        {
          path: "edit",
          breadcrumb: t("salonProfile.edit"),
        },
      ],
    },
    {
      path: "services",
      breadcrumb: t("sidebar.navigation.mySalon.children.servicesAndPrices"),
      children: [
        {
          path: ":headCategoryUuid",
          breadcrumb: HeadCategoryBreadcrumb,
          children: [
            {
              path: ":categoryUuid",
              breadcrumb: CategoryBreadcrumb,
            },
          ],
        },
      ],
    },
    {
      path: "equipment",
      breadcrumb: t("equipment.name"),
    },
  ];
  const breadcrumbs = useBreadcrumbs(routes, {
    excludePaths: ["/messages"],
  });

  return (
    <nav className="hidden md:flex" aria-label="Breadcrumb">
      <ol role="list" className="flex items-center">
        {breadcrumbs.map((crumb, index) => (
          <li key={crumb.key}>
            <div className="flex items-center">
              {index !== 0 && (
                <svg
                  className="h-5 w-5 flex-shrink-0 text-stone-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  aria-hidden="true">
                  <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                </svg>
              )}
              <NavLink
                to={crumb.match.pathname}
                className="text-xs font-medium text-stone-500 hover:font-medium hover:text-stone-600">
                {crumb.breadcrumb}
              </NavLink>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
};
