import { DeepPartial } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Button } from "@/components/ui/Button";
import { DateField } from "@/components/ui/DateField";
import { Label } from "@/components/ui/Label";
import { TextAreaField } from "@/components/ui/TextAreaField";
import { TextField } from "@/components/ui/TextField";
import { TimeField } from "@/components/ui/TimeField";
import { useBlockedSlotForm } from "@/features/calendar/hooks/use-blocked-slot-form";
import { EmployeeSelect } from "@/features/employees/components/EmployeeSelect";
import { useSessionStore } from "@/features/session/hooks";
import { Timerange, UpdateBlockedSlotInput } from "@/types";
import { timerangeIsValid } from "@/utils/datetime";
import { isNotWhitespace } from "@/utils/form";
import { cn } from "@/utils/utils";

export type BlockedSlotFormInput = UpdateBlockedSlotInput & {
  date: Date;
};

export type BlockedSlotFormProps = {
  onClose?: () => void;
  defaultValues: DeepPartial<BlockedSlotFormInput> & {
    timeRange: Timerange;
  };
  action: "create" | "update";
};

export const BlockedSlotForm = ({ onClose, defaultValues, action }: BlockedSlotFormProps) => {
  const { t } = useTranslation();
  const { onSubmit, handleDelete, form } = useBlockedSlotForm({
    onClose,
    defaultValues,
    action,
  });

  const {
    register,
    control,
    getValues,
    handleSubmit,
    formState: { errors },
  } = form;

  const { isSalonPlan, permissions } = useSessionStore();

  return (
    <form className="grid gap-4" onSubmit={handleSubmit(onSubmit)}>
      <TextField
        {...register("title", {
          validate: {
            isNotWhitespace,
          },
        })}
        placeholder={t("blockedSlots.placeholders.title")}
        showLabel
        disabled={!permissions.edit_slot_block}
        label={t("generic.title")}
      />
      {isSalonPlan && (
        <EmployeeSelect
          control={control}
          name="employeeUuid"
          disabled={!permissions.edit_slot_block}
        />
      )}
      <TextAreaField
        {...register("description")}
        showLabel
        label={t("generic.description")}
        disabled={!permissions.edit_slot_block}
      />
      <div className="flex gap-4">
        <DateField
          control={control}
          name="date"
          className="grow"
          label={t("generic.date")}
          portalId="date"
          error={errors.date}
          disabled={!permissions.edit_slot_block}
        />
        <div className="flex items-center gap-2">
          <div>
            <Label htmlFor={"timeRange.from"}>{t("generic.time")}</Label>
            <div className="flex items-center gap-2">
              <TimeField
                required
                control={control}
                name={`timeRange.from`}
                rules={{
                  required: true,
                  deps: [`timeRange.to`],
                  validate: (value) => timerangeIsValid(value, getValues(`timeRange.to`)),
                }}
                isError={!!errors.timeRange?.from}
                disabled={!permissions.edit_slot_block}
              />
              &mdash;
              <TimeField
                required
                control={control}
                name={`timeRange.to`}
                rules={{
                  required: true,
                  deps: [`from`],
                  validate: (value) => timerangeIsValid(getValues(`timeRange.from`), value),
                }}
                isError={!!errors.timeRange?.to}
                disabled={!permissions.edit_slot_block}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className={cn("grid gap-4", {
          "md:grid-cols-2": action === "update",
        })}>
        {action === "update" && (
          <Button
            variant="danger-outline"
            onClick={handleDelete}
            disabled={!permissions.delete_slot_block}>
            {t("generic.delete")}
          </Button>
        )}
        <Button
          type="submit"
          disabled={
            (action === "create" && !permissions.add_slot_block) ||
            (action === "update" && !permissions.edit_slot_block)
          }>
          {action === "create" ? t("blockedSlots.blockSlot") : t("generic.save")}
        </Button>
      </div>
    </form>
  );
};
