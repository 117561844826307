import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import { Listbox } from "@headlessui/react";

import { CheckIcon, PencilIcon } from "@heroicons/react/24/outline";

import { Button } from "@/components/ui/Button";
import { Spinner } from "@/components/ui/Spinner";
import { useSessionStore } from "@/features/session/hooks";
import { Option } from "@/utils/select-utils";
import { cn } from "@/utils/utils";

type TagsSelectOptionsProps = {
  options: Option[];
  selected: string;
  loading: boolean;
  addNew?: () => void;
  selectAll?: () => void;
  deselectAll?: () => void;
  onEdit?: (tag: Option) => void;
};

export const TagsSelectOptions = ({
  options,
  selected,
  loading,
  addNew,
  selectAll,
  deselectAll,
  onEdit,
}: TagsSelectOptionsProps) => {
  const { t } = useTranslation();
  const { permissions } = useSessionStore();

  const isOptionSelected = (option: Option) => {
    if (Array.isArray(selected)) {
      return selected.includes(option.value);
    }

    return option.value === selected;
  };

  return (
    <Listbox.Options className="mt-1 max-h-72 w-full overflow-auto rounded-md bg-white py-1 text-sm shadow-lg shadow-stone-300 ring-1 ring-stone-300 focus:outline-none">
      <div className="mt-1">
        {(selectAll || deselectAll || addNew) && (
          <div className="flex select-none flex-wrap justify-start gap-2 bg-stone-100 p-3">
            {selectAll && (
              <Button
                className="w-fit flex-1 px-2"
                size="tiny"
                variant="secondary-outline"
                disabled={options.length === selected.length}
                onClick={selectAll}>
                {t("actions.selectAll")}
              </Button>
            )}
            {deselectAll && (
              <Button
                className="w-fit flex-1 px-2"
                size="tiny"
                variant="secondary-outline"
                disabled={selected.length === 0}
                onClick={deselectAll}>
                {t("actions.deselectAll")}
              </Button>
            )}
            {addNew && (
              <Button
                size="tiny"
                variant="primary"
                onClick={addNew}
                className="flex-1 px-2"
                disabled={!permissions.add_tag}>
                {t("actions.addNewTag")}
              </Button>
            )}
          </div>
        )}
        {options.map((option: Option) => (
          <Listbox.Option key={option.value} value={option.value} as={Fragment}>
            {({ active }) => (
              <li
                className={cn(
                  active && `bg-stone-50 text-stone-900`,
                  !active && isOptionSelected(option) && `bg-stone-100 text-stone-900`,
                  `relative flex cursor-pointer select-none gap-4 px-4 py-3 text-stone-600`,
                )}>
                <div
                  className={cn(
                    isOptionSelected(option)
                      ? `cursor-pointer border-gold-600 bg-gold-500 text-white`
                      : "cursor-pointer border-stone-400 bg-white hover:border-stone-500",
                    "h-5 w-5 shrink-0 rounded border p-[1px] shadow-sm transition-colors",
                  )}>
                  {isOptionSelected(option) && <CheckIcon />}
                </div>
                <div className="flex w-full justify-between">
                  <p>{option.label}</p>
                  <Button
                    size="tiny"
                    variant="neutral"
                    className="group flex items-center gap-1"
                    disabled={!permissions.edit_tag}
                    onClick={() => onEdit && onEdit(option)}>
                    <PencilIcon
                      className={cn(
                        !permissions.edit_tag
                          ? "cursor-not-allowed text-stone-400"
                          : "text-stone-500 group-hover:text-stone-900",
                        "h-4 w-4",
                      )}
                    />
                  </Button>
                </div>
              </li>
            )}
          </Listbox.Option>
        ))}
      </div>
      {loading && (
        <div className="flex justify-center py-4">
          <Spinner className="h-8 w-8" />
        </div>
      )}
    </Listbox.Options>
  );
};
