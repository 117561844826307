import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { v4 as uuidv4 } from "uuid";

import { useSalonOpeningHoursStore, useSalonStore } from "@features/salon/hooks";

import {
  SalonDeserialized,
  UpdateSalonInputDeserialized,
} from "@/features/salon/models/salonProfile";
import { useSessionStore } from "@/features/session/hooks";
import { useToasts } from "@/providers/ToastsProvider";
import { PhoneType } from "@/types";
import { createAwsS3Url, removeImageFromS3, uploadImageToS3 } from "@/utils/s3-utils";

import { RegularAvailability } from "../models/specialHours";

type Input = Omit<UpdateSalonInputDeserialized, "logoImageUrl" | "backgroundImageUrl"> & {
  logoImage: FileList;
  backgroundImage: FileList;
  regularOpeningHours: RegularAvailability;
};

export const useSalonProfileForm = (
  salon: SalonDeserialized,
  regularOpeningHours: RegularAvailability,
) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { showToast } = useToasts();
  const { isFreePlan } = useSessionStore();
  const { updateSalon, loading: loadingSalon } = useSalonStore();
  const { update: updateRegularOpeningHours, loading: loadingOpeningHours } =
    useSalonOpeningHoursStore();

  const loading = loadingSalon || loadingOpeningHours;

  const form = useForm<Input>({
    mode: "all",
    defaultValues: {
      ...salon,
      phoneType: salon.phoneType || PhoneType.Mobile,
      regularOpeningHours,
    },
    shouldUnregister: true,
  });

  const isPublic = useWatch({ name: "isPublic", control: form.control });

  const onSubmit: SubmitHandler<Input> = async (formData) => {
    const images = [
      {
        name: "logoImageUrl",
        file: formData.logoImage[0],
        filename: uuidv4(),
        defaultUrl: salon?.logoImageUrl,
      },
      {
        name: "backgroundImageUrl",
        file: formData.backgroundImage[0],
        filename: uuidv4(),
        defaultUrl: salon?.backgroundImageUrl,
      },
    ];

    for (const file of images) {
      if (file.file && typeof file.file !== "string") {
        await uploadImageToS3(file.file, file.filename);

        if (file.defaultUrl) {
          const oldFilename = file.defaultUrl.split("/").at(-1);

          if (oldFilename) {
            await removeImageFromS3(oldFilename);
          }
        }
      }
    }

    const urls = images.reduce(
      (prev, image) => ({
        ...prev,
        [image.name]: image.file ? createAwsS3Url(image.filename) : image.defaultUrl,
      }),
      {},
    );

    const { regularOpeningHours, ...salonFormData } = formData;

    const openingHoursInput = {
      regular: JSON.parse(JSON.stringify(regularOpeningHours)),
    };

    const input = {
      ...salonFormData,
      ...urls,
      logoImage: undefined,
      backgroundImage: undefined,
    };

    const { errors: salonErrors } = await updateSalon(input);
    const { errors: openingHoursErrors } = await updateRegularOpeningHours(openingHoursInput);

    if (!salonErrors && !openingHoursErrors) {
      showToast({
        type: "success",
        title: t("salonProfile.updateToast.title"),
        description: t("salonProfile.updateToast.message"),
      });
      navigate("/salon");
    }
  };

  return {
    onSubmit,
    isPublic,
    loading,
    isFreePlan,
    salon,
    regularOpeningHours,
    form,
  };
};
