import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Transition } from "@headlessui/react";

import { EnvelopeIcon, UserIcon } from "@heroicons/react/24/outline";

import { Button } from "@components/ui/Button";
import { PhoneField } from "@components/ui/PhoneField";
import { Spinner } from "@components/ui/Spinner";
import { TextField } from "@components/ui/TextField";

import { useAccountStore } from "@features/account/hooks";
import { useSessionStore } from "@features/session/hooks";

import { ConfirmationDialog } from "@/components/dialogs/ConfirmationDialog";
import { FormSection } from "@/components/layout/FormSection";
import { Switch } from "@/components/ui/Switch";
import { Tooltip } from "@/components/ui/Tooltip";
import { NAME } from "@/constants/validations";
import { useEmployeeStore } from "@/features/employees/hooks/use-employee-store";
import { TreatmentSelect } from "@/features/services/components/treatments/TreatmentSelect";
import { useConfirmationDialog } from "@/hooks/use-confirmation-dialog";
import { useToasts } from "@/providers/ToastsProvider";
import { AccountUpdateInput, CurrentSession, UpdateEmployeeInput } from "@/types";
import { isNotWhitespace } from "@/utils/form";
import { capitalize } from "@/utils/utils";

type AccountFormProps = {
  session: CurrentSession;
};

type FormData = AccountUpdateInput & Pick<UpdateEmployeeInput, "treatmentsUuids" | "receiveEmails">;

const AccountForm = ({ session }: AccountFormProps) => {
  const { t } = useTranslation();
  const { showToast } = useToasts();

  const { accountUuid: id } = session;

  const { loadingUpdateAccount, updateAccountError, updateAccount } = useAccountStore();
  const { isFreePlan, isOwner, logout } = useSessionStore();
  const { employee, updateEmployee, deleteEmployee } = useEmployeeStore(isOwner ? id : undefined);

  const confirmationDialogState = useConfirmationDialog();

  const {
    register,
    formState: { errors, isValid, isDirty },
    control,
    handleSubmit,
    setValue,
    reset,
  } = useForm<FormData>({
    mode: "all",
    shouldUnregister: true,
    defaultValues: {
      treatmentsUuids: employee?.treatments.map(({ uuid }) => uuid) || [],
      receiveEmails: employee?.receiveEmails,
      ...session,
    },
  });

  useEffect(() => {
    reset({
      treatmentsUuids: employee?.treatments.map(({ uuid }) => uuid) || [],
      receiveEmails: employee?.receiveEmails,
      ...session,
    });
  }, [session, employee, reset]);

  const handleUpdateClient: SubmitHandler<FormData> = (data) => {
    const { treatmentsUuids, receiveEmails, ...rest } = data;

    updateAccount({
      ...rest,
      email: undefined,
    });

    updateEmployee(
      {
        treatmentsUuids,
        receiveEmails,
      },
      session.accountUuid,
    );
  };

  const handleDeleteAccount = () => {
    id &&
      confirmationDialogState.open({
        title: t("generic.areYouSure"),
        message: t("settings.account.deleteAccountPrompt"),
        onConfirm: async () => {
          const { errors } = await deleteEmployee(id);
          if (errors) {
            showToast({
              type: "error",
              title: t("generic.oops"),
              description: t("generic.somethingWentWrong"),
            });
          } else {
            logout();
          }
        },
      });
  };

  useEffect(() => {
    setValue("treatmentsUuids", employee?.treatments.map(({ uuid }) => uuid) || []);
  }, [employee?.treatments, setValue]);

  return (
    <form
      className="grid gap-2 divide-y-[1px] @container xl:gap-8"
      onSubmit={handleSubmit(handleUpdateClient)}
      noValidate>
      <FormSection
        headingText={t("settings.account.generalInfo.header")}
        descriptionText={t("settings.account.generalInfo.description")}>
        <div className="grid gap-x-6 gap-y-6 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2">
          <TextField
            {...register("firstName", {
              onChange(event) {
                const { value } = event.target;

                const capitalized = capitalize(value);

                event.target.value = capitalized.trim();
              },
              required: true,
              minLength: NAME.MIN_LENGTH,
              maxLength: NAME.MAX_LENGTH,
              validate: {
                isNotWhitespace,
              },
            })}
            label={t("generic.firstName")}
            showLabel
            placeholder={t("generic.firstName")}
            startIcon={<UserIcon />}
            errorMessage={
              errors.firstName &&
              t(`validation.${errors.firstName.type}`, {
                name: t("generic.firstName"),
                minLength: NAME.MIN_LENGTH,
                maxLength: NAME.MAX_LENGTH,
              })
            }
          />

          <TextField
            {...register("lastName", {
              onChange(event) {
                const { value } = event.target;

                const capitalized = capitalize(value);

                event.target.value = capitalized.trim();
              },
              required: true,
              minLength: NAME.MIN_LENGTH,
              maxLength: NAME.MAX_LENGTH,
              validate: {
                isNotWhitespace,
              },
            })}
            showLabel
            label={t("generic.lastName")}
            placeholder={t("generic.lastName")}
            startIcon={<UserIcon />}
            errorMessage={
              errors.lastName &&
              t(`validation.${errors.lastName.type}`, {
                name: t("generic.lastName"),
                minLength: NAME.MIN_LENGTH,
                maxLength: NAME.MAX_LENGTH,
              })
            }
          />

          <PhoneField
            name="phone"
            control={control}
            label={t("generic.phone")}
            showLabel
            placeholder={t("generic.phone")}
            errorMessage={
              errors.phone &&
              t(`validation.${errors.phone.type}`, {
                name: t("generic.phone"),
              })
            }
          />

          <TextField
            {...register("email", {
              onChange(event) {
                event.target.value = event.target.value.trim();
              },
            })}
            showLabel
            label={t("generic.email")}
            startIcon={<EnvelopeIcon />}
            disabled
          />
        </div>
      </FormSection>

      <FormSection
        headingText={t("settings.account.receiveEmails.header")}
        descriptionText={t("settings.account.receiveEmails.description")}>
        <Switch
          name="receiveEmails"
          control={control}
          label={t("settings.account.receiveEmails.label")}
          showLabel
        />
      </FormSection>

      {isOwner && (
        <>
          <FormSection
            headingText={t("settings.account.treatments.header")}
            descriptionText={t("settings.account.treatments.description")}>
            <Tooltip
              content={t("selfBooking.onlyAvailableInPaidPlan")}
              disabled={!isFreePlan}
              href="/subscriptions">
              <div>
                <TreatmentSelect
                  control={control}
                  name="treatmentsUuids"
                  label={t("employees.treatmentsThatEmployeeHasAbilityToDo")}
                  multiple
                />
              </div>
            </Tooltip>
          </FormSection>

          <FormSection
            headingText={t("settings.account.delete.header")}
            descriptionText={t("settings.account.delete.description")}>
            <Button className="max-w-xs" variant="danger" onClick={handleDeleteAccount}>
              {t("actions.deleteAccount")}
            </Button>
          </FormSection>
        </>
      )}

      {updateAccountError && <p className="my-4 text-red-500">{updateAccountError.message}</p>}

      <div className="flex w-full flex-auto flex-col justify-end gap-4 px-4 pt-8 sm:flex-row">
        <Button
          type="submit"
          fullWidth
          disabled={loadingUpdateAccount || !isValid || !isDirty}
          className="w-full sm:w-48">
          {t("generic.save")}
        </Button>
      </div>

      <ConfirmationDialog dialogState={confirmationDialogState} />
    </form>
  );
};

export const AccountEdit = () => {
  const { session, loading } = useSessionStore();

  return (
    <>
      <Transition
        show={loading}
        as="div"
        enter="transition-opacity duration-500"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-500"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className="flex justify-center p-10">
        <Spinner className="h-8 w-8" />
      </Transition>
      <Transition
        show={!loading}
        appear
        as="div"
        enter="transition-opacity duration-500"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-500"
        leaveFrom="opacity-100"
        leaveTo="opacity-0">
        {session && <AccountForm session={session} />}
      </Transition>
    </>
  );
};
