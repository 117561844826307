import { InputHTMLAttributes, forwardRef, ForwardedRef } from "react";
import { Control, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { cn } from "@/utils/utils";

import { Badge } from "./Badge";

type RadioOption = {
  value: string;
  label: string;
  badge?: string;
  disabled?: boolean;
};

type RadioProps = InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  translateLabels?: boolean;
  control: Control<any>;
  options: RadioOption[];
  name: string;
  placeholder?: string;
  errorMessage?: string;
  required?: boolean;
};

export const Radio = forwardRef(
  (
    {
      label,
      translateLabels,
      control,
      options,
      name,
      errorMessage,
      required,
      ...delegated
    }: RadioProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const { t } = useTranslation();

    return (
      <div>
        {label && <label className="block text-xs text-stone-500">{label}</label>}
        <fieldset className="mt-2">
          <legend className="sr-only">{label || name}</legend>
          <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
            {options.map((option) => (
              <div key={option.value} className="flex items-center">
                <Controller
                  control={control}
                  name={name}
                  rules={{ required }}
                  render={({ field: { onChange, value } }) => (
                    <input
                      ref={ref}
                      id={option.value}
                      name={name}
                      type="radio"
                      checked={option.value === value}
                      className={cn(
                        "h-4 w-4 border-stone-300 text-gold-500 focus:ring-gold-500 disabled:cursor-not-allowed disabled:bg-stone-50 ",
                        option.disabled ? "opacity-60" : "cursor-pointer",
                      )}
                      onChange={onChange}
                      value={option.value}
                      disabled={delegated.disabled || option.disabled}
                      {...delegated}
                    />
                  )}
                />
                <label
                  htmlFor={option.value}
                  className={cn(
                    "ml-3 block text-sm font-medium text-stone-700",
                    option.disabled ? "opacity-60" : "cursor-pointer",
                  )}>
                  {translateLabels ? t(option.label) : option.label}
                  {option.badge && (
                    <Badge className="ml-1 bg-gold-500 uppercase text-gold-100">
                      {option.badge}
                    </Badge>
                  )}
                </label>
              </div>
            ))}
          </div>
        </fieldset>
        {errorMessage && <span className="block text-xs text-red-600">{errorMessage}</span>}
      </div>
    );
  },
);
