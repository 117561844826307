import { useTranslation } from "react-i18next";

import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { Badge } from "@/components/ui/Badge";
import { Employee } from "@/types";
import { cn } from "@/utils/utils";

const setEmployeeStatus = (employee: Employee) => {
  if (!employee.isAvailableForAppointments)
    return {
      status: "unavailable",
      color: "orange",
    };

  return employee.isActive
    ? {
        status: "active",
        color: "green",
      }
    : {
        status: "invited",
        color: "blue",
      };
};

type EmployeesDirectoryItemProps = {
  employee: Employee;
  selectedEmployeeUuid?: string;
  handleSelect: (employee: Employee) => void;
  isDraggable?: boolean;
};

export const EmployeesDirectoryItem = ({
  employee,
  selectedEmployeeUuid,
  handleSelect,
  isDraggable = false,
}: EmployeesDirectoryItemProps) => {
  const { t } = useTranslation();

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: employee.accountUuid,
    disabled: !isDraggable,
  });

  const employeeStatus = setEmployeeStatus(employee);

  return (
    <li
      ref={setNodeRef}
      style={{
        transform: CSS.Transform.toString(transform),
        transition,
        touchAction: "none",
      }}
      {...attributes}
      {...listeners}
      className={cn(
        "relative flex items-center gap-4 px-6 py-5 hover:bg-stone-100",
        employee.accountUuid === selectedEmployeeUuid && "bg-stone-200/50",
      )}>
      <div className="min-w-0 flex-1">
        <a
          href="#"
          onClick={!isDraggable ? () => handleSelect(employee) : () => {}}
          className="focus:outline-none">
          {/* Extend touch target to entire panel */}
          <span className="absolute inset-0" aria-hidden="true" />
          <div className="flex w-full">
            <div className="flex-1 truncate">
              <p className="mb-1 text-sm font-medium text-stone-900">{employee.fullName}</p>
              <p className="truncate text-sm font-light text-stone-500">
                {t(`employees.role.${employee.role.toLowerCase()}`)}
              </p>
            </div>
            <div className="ml-2 min-w-fit flex-shrink-0">
              <Badge size="sm" color={employeeStatus.color} bordered>
                {t(`employees.status.${employeeStatus.status}`)}
              </Badge>
            </div>
          </div>
        </a>
      </div>
    </li>
  );
};
