import { Navigate, Outlet } from "react-router-dom";

import { useFlags } from "@features/feature-flags/providers/FlagsContext";

import { useSessionStore } from "@features/session/hooks";

import { Spinner } from "@/components/ui/Spinner";
import type { Role, SubscriptionPlan } from "@/types";

type ProtectedRoutesProps = {
  roles?: Role[];
  plans?: SubscriptionPlan[];
  permission?: string;
  featureFlags?: string[];
};

export const ProtectedRoutes = ({
  roles,
  plans,
  featureFlags,
  permission,
}: ProtectedRoutesProps) => {
  const { loading, role, salonPlan, permissions } = useSessionStore();
  const { flags, loading: loadingFlags } = useFlags();

  if (loading || (featureFlags && loadingFlags)) {
    return (
      <div className="absolute inset-1/2 h-[15vh] w-[15vh] -translate-x-1/2 -translate-y-1/2">
        <Spinner className="h-full w-full opacity-50" />
      </div>
    );
  }

  if (
    (role && roles && !roles?.includes(role)) ||
    (salonPlan && plans && !plans?.includes(salonPlan)) ||
    (permission && !permissions[permission]) ||
    (featureFlags &&
      !featureFlags.every((flag) => flags.find(({ name }) => name === flag)?.enabled))
  ) {
    return <Navigate to="/calendar" replace />;
  }

  return <Outlet />;
};
