import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { GENERIC_NAME } from "@constants/validations";

import { DefaultDialog } from "@components/dialogs/DefaultDialog";
import { Button } from "@components/ui/Button";
import { Spinner } from "@components/ui/Spinner";
import { TextField } from "@components/ui/TextField";

import { useCategoryStore } from "@features/services/hooks/use-category-store";

import { ColorPicker } from "@/components/ui/ColorPicker";
import { AddCategoryInput, Category } from "@/types";

export type CategoryFormProps = {
  categoryUuid?: string;
  headCategoryUuid: string;
  onRemove?: (category: Category) => Promise<boolean>;
};

type CategoryForm = {
  category?: Category | null;
  headCategoryUuid: string;
  onClose: () => void;
  onRemove?: (category: Category) => Promise<boolean>;
};

type CategoryFormDialogProps = {
  open: boolean;
  props?: CategoryFormProps;
  onClose: () => void;
};

type FormData = AddCategoryInput;

export const CategoryFormDialog = ({ open, props, onClose }: CategoryFormDialogProps) => {
  const { t } = useTranslation();
  const { category, loading } = useCategoryStore(props?.categoryUuid);

  return (
    <DefaultDialog
      open={open}
      onClose={onClose}
      title={
        props?.categoryUuid ? (
          <span>{t("services.categories.edit")}</span>
        ) : (
          <span>{t("services.categories.addNew")}</span>
        )
      }>
      {loading ? (
        <div className="flex justify-center p-10">
          <Spinner className="h-8 w-8" />
        </div>
      ) : (
        props?.headCategoryUuid && (
          <CategoryForm
            onClose={onClose}
            category={category}
            headCategoryUuid={props.headCategoryUuid}
          />
        )
      )}
    </DefaultDialog>
  );
};

const CategoryForm = ({ category, headCategoryUuid, onRemove, onClose }: CategoryForm) => {
  const { t } = useTranslation();
  const { addCategory, updateCategory, loadingAdd, loadingUpdate } = useCategoryStore();

  const {
    control,
    register,
    handleSubmit,
    formState: { isValid, isDirty },
  } = useForm<FormData>({
    mode: "all",
    shouldUnregister: true,
    defaultValues: { ...category },
  });

  const handleCategoryForm = async (data: FormData) => {
    const { errors } = category
      ? await updateCategory({ name: data.name, color: data.color }, category?.uuid)
      : await addCategory({
          ...data,
          headCategoryUuid,
        });
    if (!errors) {
      onClose();
    }
  };

  const handleCategoryDelete = async () => {
    if (!onRemove || !category) return;

    const result = await onRemove(category);
    result && onClose();
  };

  return (
    <form className="relative space-y-4" onSubmit={handleSubmit(handleCategoryForm)} noValidate>
      <TextField
        {...register("name", GENERIC_NAME)}
        label={t("services.categories.categoryName")}
        showLabel
        placeholder={t("services.categories.categoryNamePlaceholder")}
      />
      <ColorPicker control={control} name="color" label={t("generic.color")} />
      <p className="text-stone-500">{t("services.categories.categoryColorDescription")}</p>
      <div className="mt-4 flex flex-col items-center gap-4 md:flex-row">
        {category && (
          <Button variant="danger" fullWidth onClick={handleCategoryDelete}>
            {t("generic.delete")}
          </Button>
        )}
        <Button
          variant="primary"
          fullWidth
          type="submit"
          disabled={!isValid || !isDirty || loadingAdd || loadingUpdate}>
          {category ? (
            <span>{t("services.categories.edit")}</span>
          ) : (
            <span>{t("services.categories.addNew")}</span>
          )}
        </Button>
      </div>
    </form>
  );
};
