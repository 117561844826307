import { useTranslation } from "react-i18next";

import { formatTreatmentPrice } from "@features/payments/utils";

import { Badge } from "@components/ui/Badge";

import { Treatment } from "@/types";

import { formatTreatmentDuration } from "../../utils";

type TreatmentEntityProps = {
  entity: Treatment;
};

export const TreatmentEntity = ({ entity }: TreatmentEntityProps) => {
  const { t, i18n } = useTranslation();

  return (
    <div className="flex flex-col">
      <span>{entity.name}</span>
      <div className="flex text-xs font-normal text-stone-400">
        {formatTreatmentPrice({
          ...entity,
        })}
        {entity?.duration && (
          <div>&nbsp;&bull;&nbsp;{formatTreatmentDuration(entity.duration, i18n.language)}</div>
        )}
      </div>
      {entity.isPublic && (
        <div>
          <Badge>{t("selfBooking.public_f")}</Badge>
        </div>
      )}
    </div>
  );
};
