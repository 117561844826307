import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useResizeDetector } from "react-resize-detector";

import { useEmployeesStore } from "@features/employees/hooks";

import { DefaultDialog } from "@/components/dialogs/DefaultDialog";
import { Spinner } from "@/components/ui/Spinner";
import { useSessionStore } from "@/features/session/hooks";
import { Employee } from "@/types";

import { EmployeeListActions } from "./EmployeeListActions";
import { EmployeeProfile } from "./EmployeeProfile";
import { EmployeesDirectory } from "./EmployeesDirectory";

export const EmployeeList = () => {
  const { ref, height } = useResizeDetector();

  const { loading, employees, updateEmployeesPositions } = useEmployeesStore();
  const { isOwner } = useSessionStore();

  const { t } = useTranslation();

  const [asideHeight, setAsideHeight] = useState(height);
  const [open, setOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState<Employee | undefined>(undefined);
  const [isDraggable, setIsDraggable] = useState(false);
  const [tempEmployees, setTempEmployees] = useState<Employee[]>([]);

  useEffect(() => {
    setAsideHeight(height);
  }, [height]);

  useEffect(() => {
    if (selectedEmployee) {
      return;
    }

    const firstEmployee = employees?.[0];
    if (firstEmployee) {
      setSelectedEmployee(firstEmployee);
    }
  }, [employees.length, selectedEmployee]);

  const handleEmployeeSelect = (employee: Employee) => {
    setSelectedEmployee(employee);
    setOpen(true);
  };

  const handleOrder = useCallback(() => {
    if (!isOwner) return;

    setIsDraggable((prev) => !prev);

    if (isDraggable) {
      setTempEmployees(employees);
    } else {
      setTempEmployees([]);
    }
  }, []);

  const handleSaveOrder = useCallback(() => {
    if (tempEmployees.length > 0) {
      const data = {
        sortedEmployeesUuids: tempEmployees.map((employee) => employee.accountUuid),
      };

      updateEmployeesPositions({ data: data });
    }

    setIsDraggable(false);
  }, [tempEmployees, updateEmployeesPositions]);

  if (loading || !selectedEmployee) {
    return (
      <div className="flex justify-center p-10">
        <Spinner className="h-8 w-8" />
      </div>
    );
  }

  return (
    <div className="@container">
      <div className="md:min-h-auto relative z-0 -mx-4 mt-6 flex min-h-[60vh] flex-1 overflow-hidden rounded-lg bg-white px-4 pt-1 @[670px]:m-0 @[670px]:p-0 sm:mt-0 sm:pt-0">
        <main
          ref={ref}
          className="custom-client-profile-height relative z-0 order-last hidden h-full overflow-y-auto border-l border-stone-200 focus:outline-none @[670px]:flex @[670px]:flex-1">
          <EmployeeProfile selectedEmployee={selectedEmployee} />
        </main>
        <aside
          style={{ maxHeight: asideHeight ? `${asideHeight}px` : "100%" }}
          className="order-first flex w-full flex-col @[670px]:w-72 lg:w-80 xl:w-96">
          <div className="mb-1 @[670px]:pb-28">
            {isOwner && employees.length > 1 && (
              <EmployeeListActions
                isDraggable={isDraggable}
                handleOrder={handleOrder}
                handleSaveOrder={handleSaveOrder}
              />
            )}
            <EmployeesDirectory
              employees={
                isDraggable ? (tempEmployees.length > 0 && tempEmployees) || employees : employees
              }
              selectedEmployee={selectedEmployee}
              handleSelect={handleEmployeeSelect}
              isDraggable={isDraggable}
              setTempEmployees={setTempEmployees}
            />
          </div>
        </aside>

        {!asideHeight && (
          <DefaultDialog open={open} title={t("generic.details")} onClose={() => setOpen(false)}>
            <EmployeeProfile selectedEmployee={selectedEmployee} />
          </DefaultDialog>
        )}
      </div>
    </div>
  );
};
