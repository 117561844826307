import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { SalonFragmentDoc } from '../fragments/Salon.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchSalonQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type FetchSalonQuery = { __typename?: 'RootQueryType', fetchSalon: { __typename?: 'Salon', salonUuid: any, name: string, phoneType?: Types.PhoneType | null, phone?: string | null, mobile?: string | null, email?: string | null, city?: string | null, links?: any | null, logoImageUrl?: string | null, backgroundImageUrl?: string | null, postalCode?: string | null, street?: string | null, streetNumber?: string | null, socials?: any | null, description?: string | null, isPublic: boolean, slug?: string | null, selfBooking: { __typename?: 'SalonSelfBooking', slotDuration: number, automaticalSlotApproval: boolean, automaticalSlotApprovalSms: boolean, minLeadTime: Types.MinLeadTimeOptionsInMinutes, maxLeadTime: Types.MaxLeadTimeOptionsInDays, url: any } } };


export const FetchSalonDocument = gql`
    query FetchSalon {
  fetchSalon {
    ...Salon
  }
}
    ${SalonFragmentDoc}`;

/**
 * __useFetchSalonQuery__
 *
 * To run a query within a React component, call `useFetchSalonQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchSalonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchSalonQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchSalonQuery(baseOptions?: Apollo.QueryHookOptions<FetchSalonQuery, FetchSalonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchSalonQuery, FetchSalonQueryVariables>(FetchSalonDocument, options);
      }
export function useFetchSalonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchSalonQuery, FetchSalonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchSalonQuery, FetchSalonQueryVariables>(FetchSalonDocument, options);
        }
export type FetchSalonQueryHookResult = ReturnType<typeof useFetchSalonQuery>;
export type FetchSalonLazyQueryHookResult = ReturnType<typeof useFetchSalonLazyQuery>;
export type FetchSalonQueryResult = Apollo.QueryResult<FetchSalonQuery, FetchSalonQueryVariables>;