type FormSectionProps = {
  children: React.ReactNode;
  headingText: string;
  descriptionText: string;
};

export const FormSection = ({ children, headingText, descriptionText }: FormSectionProps) => {
  return (
    <section className="py-8 @[800px]:grid @[800px]:grid-cols-3 @[800px]:gap-6">
      <div className="pb-4 @[800px]:col-span-1 @[800px]:pb-0">
        <h3 className="mb-2 font-serif text-lg leading-6 text-stone-700 @[800px]:mb-0">
          {headingText}
        </h3>
        <p className="mt-1 max-w-sm text-sm text-stone-500">{descriptionText}</p>
      </div>
      <div className="mt-4 @[800px]:mt-0 md:col-span-2">
        <div className="space-y-6">{children}</div>
      </div>
    </section>
  );
};
