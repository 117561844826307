import { ButtonHTMLAttributes, ReactNode } from "react";
import { NavLink, To } from "react-router-dom";

import { Spinner } from "@/components/ui/Spinner";
import { cn } from "@/utils/utils";

export type ButtonVariant =
  | "primary"
  | "secondary"
  | "warning"
  | "danger"
  | "success"
  | "info"
  | "primary-inline"
  | "secondary-inline"
  | "warning-inline"
  | "danger-inline"
  | "success-inline"
  | "info-inline"
  | "primary-outline"
  | "secondary-outline"
  | "warning-outline"
  | "danger-outline"
  | "success-outline"
  | "info-outline"
  | "neutral";

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement | HTMLAnchorElement> & {
  children: ReactNode;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  className?: string;
  to?: To;
  variant?: ButtonVariant;
  size?: "tiny" | "small" | "medium" | "large";
  fullWidth?: boolean;
  loading?: boolean;
};

const ButtonInner = ({
  children,
  startIcon,
  endIcon,
  size,
  variant,
}: Pick<ButtonProps, "children" | "startIcon" | "endIcon" | "size" | "variant">) => (
  <>
    {startIcon && (
      <span
        className={cn("inline-flex", {
          "mr-1 h-3 w-3": size === "tiny",
          "mr-1.5 h-4 w-4": size === "small",
          "mr-2 h-5 w-5": size === "medium",
          "mr-2.5 h-6 w-6": size === "large",
          "-ml-0.5": !variant?.includes("inline-"),
        })}
        aria-hidden="true">
        {startIcon}
      </span>
    )}
    {children}
    {endIcon && (
      <span
        className={cn("mr-1.5 inline-flex", {
          "mr-1 h-3 w-3": size === "tiny",
          "mb-0.5 mr-1.5 h-4 w-4": size === "small",
          "mr-2 h-5 w-5": size === "medium",
          "mr-2.5 h-6 w-6": size === "large",
          "ml-1": !variant?.includes("inline-"),
        })}
        aria-hidden="true">
        {endIcon}
      </span>
    )}
  </>
);

export const Button = ({
  children,
  startIcon,
  endIcon,
  variant = "primary",
  size = "medium",
  className,
  to,
  fullWidth = false,
  loading = false,
  ...delegated
}: ButtonProps) => {
  const isLink = !!to;

  const inlineVariants = [
    "primary-inline",
    "secondary-inline",
    "warning-inline",
    "danger-inline",
    "success-inline",
    "info-inline",
  ];

  const buttonSize = {
    tiny: "py-1 px-2 text-xs",
    small: "py-1.5 px-3 text-sm",
    medium: "py-2 px-4 text-base",
    large: "py-3 px-6 text-lg",
  };

  const buttonBase = {
    default: cn(
      "flex items-center justify-center",
      "border rounded-md",
      "transition-colors duration-150",
      {
        "w-full": fullWidth,
      },
    ),
    inline: "p-0 border-0 bg-transparent font-medium underline",
    outline: cn(
      "flex items-center justify-center",
      "border border-solid rounded-md",
      "transition-colors duration-150",
      {
        "w-full": fullWidth,
      },
    ),
    neutral: "",
  };

  const buttonVariant = {
    primary: cn(
      [buttonBase["default"]],
      "border-gold-600 hover:border-gold-700",
      "bg-gold-600 text-gold-50 hover:bg-gold-700",
      "shadow-[0_8px_25px_-5px_rgba(147,123,76,1)]",
    ),
    "primary-inline": cn([buttonBase["inline"]], "text-gold-600 hover:text-gold-700"),
    "primary-outline": cn(
      [buttonBase["outline"]],
      "border-gold-600 hover:border-gold-700",
      "text-gold-600 hover:text-gold-700",
    ),
    secondary: cn(
      [buttonBase["default"]],
      "border-stone-600 hover:border-stone-700",
      "bg-stone-600 text-stone-50 hover:bg-stone-700",
      "shadow-sm",
    ),
    "secondary-inline": cn([buttonBase["inline"]], "text-stone-600 hover:text-stone-700"),
    "secondary-outline": cn(
      [buttonBase["outline"]],
      "border-stone-500 hover:border:stone-700",
      "text-stone-600 hover:text-stone-700",
    ),
    warning: cn(
      [buttonBase["default"]],
      "border-orange-600 hover:border-orange-700",
      "bg-orange-600 text-orange-50 hover:bg-orange-700",
    ),
    "warning-inline": cn([buttonBase["inline"]], "text-gold-600 hover:text-gold-700"),
    "warning-outline": cn(
      [buttonBase["outline"]],
      "border-gold-600 text-gold-600 hover:text-gold-700 hover:border-gold-700",
    ),
    danger: cn(
      [buttonBase["default"]],
      "border-red-600 hover:border-red-700",
      "bg-red-600 text-red-50 hover:bg-red-700",
    ),
    "danger-inline": cn([buttonBase["inline"]], "text-red-600 hover:text-red-700"),
    "danger-outline": cn(
      [buttonBase["outline"]],
      "border-red-600 text-red-600 hover:text-red-700 hover:border-red-700",
    ),
    success: cn(
      [buttonBase["default"]],
      "border-green-600 hover:border-green-700",
      "bg-green-600 text-green-50 hover:bg-green-700",
    ),
    "success-inline": cn([buttonBase["inline"]], "text-green-600 hover:text-green-700"),
    "success-outline": cn(
      [buttonBase["outline"]],
      "border-green-600 text-green-600 hover:text-green-700 hover:border-green-700",
    ),
    info: cn(
      [buttonBase["default"]],
      "border-blue-600 hover:border-blue-700",
      "bg-blue-600 text-blue-50 hover:bg-blue-700",
    ),
    "info-inline": cn([buttonBase["inline"]], "text-blue-600 hover:text-blue-700"),
    "info-outline": cn(
      [buttonBase["outline"]],
      "border-blue-600 text-blue-600 hover:text-blue-700 hover:border-blue-700",
    ),
    neutral: cn([buttonBase["neutral"]]),
  };

  const buttonDisabled = {
    default: cn(
      [buttonBase["default"]],
      "border-stone-300",
      "bg-stone-300",
      "text-stone-50",
      "cursor-not-allowed",
    ),
    inline: cn([buttonBase["inline"]], "text-stone-400 cursor-not-allowed"),
    outline: cn(
      [buttonBase["outline"]],
      "border-stone-300",
      "text-stone-300",
      "cursor-not-allowed",
    ),
    neutral: "",
  };

  const resolveVariant = (variant: string) => {
    if (variant.includes("-inline")) return buttonDisabled["inline"];
    if (variant.includes("-outline")) return buttonDisabled["outline"];
    if (variant === "neutral") return buttonDisabled["neutral"];
    return buttonDisabled["default"];
  };

  const classes = cn(
    {
      [buttonSize[size]]: size && !inlineVariants.includes(variant) && variant !== "neutral",
      [buttonVariant[variant]]: variant && !delegated.disabled,
      [resolveVariant(variant)]: delegated.disabled,
    },
    className,
  );

  return isLink ? (
    <NavLink to={to} className={classes} {...delegated}>
      <ButtonInner
        size={size}
        variant={variant}
        {...(loading ? { startIcon: <Spinner />, disabled: true } : { startIcon, endIcon })}>
        {children}
      </ButtonInner>
    </NavLink>
  ) : (
    <button className={classes} type="button" {...delegated}>
      <ButtonInner
        size={size}
        variant={variant}
        {...(loading ? { startIcon: <Spinner />, disabled: true } : { startIcon, endIcon })}>
        {children}
      </ButtonInner>
    </button>
  );
};
