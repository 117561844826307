import { Fragment, lazy, Suspense, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import { Transition } from "@headlessui/react";

import {
  ArrowPathIcon,
  BuildingStorefrontIcon,
  CalendarDaysIcon,
  ChartBarIcon,
  ClockIcon,
  Cog6ToothIcon,
  LifebuoyIcon,
  ListBulletIcon,
  RectangleStackIcon,
  UserGroupIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";

import { useBreakpoint } from "@hooks/use-breakpoint";
import { bindDialogState, useDialog } from "@hooks/use-dialog";

import { SendFeedbackProps } from "@features/account/components/SendFeedbackDialog";

import { useSessionStore } from "@features/session/hooks";

import { useFlag } from "@/features/feature-flags/providers/FlagsContext";
import { Role } from "@/types";
import { sendEvent } from "@/utils/google-analytics";
import { cn } from "@/utils/utils";

import { NavigationCategory } from "../types/sidebar";

const SendFeedbackDialog = lazy(() => import("@features/account/components/SendFeedbackDialog"));

export const NavigationItems = ({ isSidebarShrinked }: { isSidebarShrinked: boolean }) => {
  const { t } = useTranslation();
  const { role, permissions } = useSessionStore();
  const feedbackDialogState = useDialog<SendFeedbackProps>();
  const { isMd } = useBreakpoint("md");
  const { enabled: paymentsEnabled } = useFlag("in_app_payments");

  const navigation: NavigationCategory[] = useMemo(
    () => [
      {
        label: t("sidebar.navigation.appointments.label"),
        children: [
          {
            name: t("sidebar.navigation.appointments.children.calendar"),
            icon: CalendarDaysIcon,
            href: "/calendar",
          },
          {
            name: t("sidebar.navigation.appointments.children.clients"),
            icon: UserGroupIcon,
            href: "/clients",
          },
          {
            name: t("sidebar.navigation.appointments.children.statistics"),
            icon: ChartBarIcon,
            href: "/statistics",
            hasPermission: permissions.statistics_view,
          },
        ],
      },
      {
        label: t("sidebar.navigation.mySalon.label"),
        children: [
          {
            name: t("sidebar.navigation.mySalon.children.salonProfile"),
            icon: BuildingStorefrontIcon,
            href: "/salon",
          },
          {
            name: t("sidebar.navigation.mySalon.children.employees"),
            icon: UsersIcon,
            href: "/employees",
          },
          {
            name: t("sidebar.navigation.mySalon.children.openingHours"),
            icon: ClockIcon,
            href: "/opening-hours",
          },
          {
            name: t("sidebar.navigation.mySalon.children.servicesAndPrices"),
            icon: ListBulletIcon,
            href: "/services",
          },
          {
            name: t("equipment.name"),
            icon: RectangleStackIcon,
            href: "/equipment",
          },
          {
            name: t("settings.title"),
            icon: Cog6ToothIcon,
            href: "/settings",
          },
          {
            name: t("subscriptions.title"),
            icon: ArrowPathIcon,
            href: "/subscriptions",
            roles: [Role.Owner],
            disabled: !paymentsEnabled,
          },
        ],
      },
      {
        label: t("generic.support"),
        children: [
          {
            name: t("footer.iNeedHelp"),
            icon: LifebuoyIcon,
            action: () => {
              sendEvent("support_button", "contact", "footer");
              feedbackDialogState.open();
            },
          },
        ],
      },
    ],
    [],
  );

  const navigationCategories = useMemo<NavigationCategory[]>(
    () =>
      role
        ? navigation.map((category) => ({
            ...category,
            children: category.children.filter(
              (item) =>
                (!item.roles || item.roles.includes(role)) &&
                (item.hasPermission === undefined || item.hasPermission === true),
            ),
          }))
        : [],
    [role, navigation],
  );

  return (
    <nav
      className={cn("flex-1 space-y-1 px-3 pb-10", {
        "mt-5": isSidebarShrinked,
        "mt-3": !isSidebarShrinked,
      })}
      aria-label="Sidebar">
      {navigationCategories.map((category) => (
        <div key={category.label}>
          <Transition
            show={!isSidebarShrinked || !isMd}
            as="div"
            enter="transition-all ease-in-out duration-100"
            enterFrom="opacity-0 -translate-y-5"
            enterTo="opacity-100 translate-y-0"
            leave="transition-all ease-in-out duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0 -translate-y-5">
            <span className="mb-2 mt-3 block text-xs font-semibold uppercase text-gold-500/80">
              {category.label}
            </span>
          </Transition>
          {category.children.map(
            (item) =>
              !item.disabled && (
                <Fragment key={item.name}>
                  {item.action ? (
                    <button
                      onClick={item.action}
                      className="rounded-smpy-2 group flex w-full items-center px-2 py-3 text-sm text-stone-500 hover:text-stone-700">
                      <item.icon className="mr-3 h-5 w-5 flex-shrink-0" />
                      <Transition
                        show={!isSidebarShrinked || !isMd}
                        as="span"
                        enter={`transition-all duration-150 transform delay-200 ease-out`}
                        enterFrom="opacity-0 -translate-x-1/2"
                        enterTo="opacity-100 translate-x-0"
                        leave="transition-all ease-in-out duration-150 transform"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        className="truncate">
                        {item.name}
                      </Transition>
                    </button>
                  ) : (
                    <NavLink
                      to={item.href ?? "#"}
                      className={({ isActive }) =>
                        cn(
                          "rounded-smpy-2 group flex w-full items-center px-2 py-3 text-sm transition-colors hover:font-medium",
                          isActive
                            ? isSidebarShrinked
                              ? "-ml-1 rounded-md bg-gradient-to-l from-gold-100 to-white px-3 font-medium text-gold-600"
                              : "rounded-md bg-gradient-to-l from-gold-100 to-white font-medium text-gold-600"
                            : "text-stone-500 hover:text-stone-700",
                        )
                      }>
                      <item.icon className="mr-3 h-5 w-5 flex-shrink-0" />
                      <Transition
                        show={!isSidebarShrinked || !isMd}
                        as="span"
                        enter={`transition-all duration-150 transform delay-200 ease-out`}
                        enterFrom="opacity-0 -translate-x-1/2"
                        enterTo="opacity-100 translate-x-0"
                        leave="transition-all ease-in-out duration-150 transform"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        className="truncate">
                        {item.name}
                      </Transition>
                    </NavLink>
                  )}
                </Fragment>
              ),
          )}
        </div>
      ))}
      <Suspense fallback={null}>
        {feedbackDialogState.internalState.open && (
          <SendFeedbackDialog {...bindDialogState(feedbackDialogState)} />
        )}
      </Suspense>
    </nav>
  );
};
