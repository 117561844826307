import { Controller, FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  BuildingStorefrontIcon,
  EnvelopeIcon,
  HomeIcon,
  LinkIcon,
  MapIcon,
  TicketIcon,
} from "@heroicons/react/24/outline";

import { POSTAL_CODE } from "@constants/validations";

import { Button } from "@components/ui/Button";
import { ImageField } from "@components/ui/ImageField";
import { Label } from "@components/ui/Label";
import { PhoneField } from "@components/ui/PhoneField";
import { Spinner } from "@components/ui/Spinner";
import { Switch } from "@components/ui/Switch";
import { TextAreaField } from "@components/ui/TextAreaField";
import { TextField } from "@components/ui/TextField";

import { FormSection } from "@/components/layout/FormSection";
import { CoverImageField } from "@/components/ui/CoverImageField";
import { Select } from "@/components/ui/Select";
import { useSalonProfileForm } from "@/features/salon/hooks/use-salon-profile-form";
import { isNotWhitespace, validateCoverImage, validateLogoImage } from "@/utils/form";

import { SalonDeserialized } from "../models/salonProfile";
import { RegularAvailability } from "../models/specialHours";
import {
  SELF_BOOKING_MAX_LEAD_TIME_OPTIONS,
  SELF_BOOKING_MIN_LEAD_TIME_OPTIONS,
} from "../utils/salonProfile";
import { OpeningHours } from "./OpeningHours";

type SalonProfileFormProps = {
  salon: SalonDeserialized;
  regularOpeningHours: RegularAvailability;
};

export const SalonProfileForm = ({ salon, regularOpeningHours }: SalonProfileFormProps) => {
  const { t } = useTranslation();

  const { form, onSubmit, isPublic, isFreePlan, loading } = useSalonProfileForm(
    salon,
    regularOpeningHours,
  );

  const {
    control,
    formState: { errors, isValid, isDirty },
    register,
    handleSubmit,
    watch,
  } = form;

  const selfBookingAutomaticalSlotApproval = watch("selfBooking.automaticalSlotApproval");

  const slotDurationOptions = [15, 30, 60].map((el) => ({
    value: el,
    label: `${el} min`,
  }));

  const selfBookingMinLeadTimeOptions = SELF_BOOKING_MIN_LEAD_TIME_OPTIONS.map((option) => {
    return {
      value: option,
      label: t(`salonProfile.selfBooking.selfBookingMinLeadTimeOptions.${option}`),
    };
  });

  const selfBookingMaxLeadTimeOptions = SELF_BOOKING_MAX_LEAD_TIME_OPTIONS.map((option) => {
    return {
      value: option,
      label: t(`salonProfile.selfBooking.selfBookingMaxLeadTimeOptions.${option}`),
    };
  });

  const setAutoConfirm = (data: boolean) => {
    form.setValue("selfBooking.automaticalSlotApproval", data, {
      shouldDirty: true,
    });

    form.setValue("selfBooking.automaticalSlotApprovalSms", data);
  };

  if (loading || !salon) {
    return (
      <div className="flex h-[50vh] items-center justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <FormProvider {...form}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="grid gap-2 divide-y-[1px] @container xl:gap-8">
        <FormSection
          headingText={t("salonProfile.profile.header")}
          descriptionText={t("salonProfile.profile.description")}>
          <div className="space-y-6">
            <TextField
              {...register("name", {
                required: true,
                validate: {
                  isNotWhitespace,
                },
              })}
              label={t("salonProfile.salonName.label")}
              startIcon={<BuildingStorefrontIcon />}
              showLabel
              placeholder={t("salonProfile.salonName.placeholder")}
              errorMessage={
                errors.name &&
                t(`validation.${errors.name.type}`, {
                  name: t("salonProfile.salonName.label"),
                })
              }
            />

            <TextAreaField
              {...register("description", { maxLength: 500 })}
              label={t("generic.description")}
              errorMessage={
                errors.description &&
                t(`validation.${errors.description.type}`, {
                  name: t("generic.note"),
                  maxLength: 500,
                })
              }
            />

            <ImageField
              {...register("logoImage", {
                validate: {
                  maxFileSize: (value) => validateLogoImage(value),
                  allowedTypes: (value) => validateLogoImage(value),
                },
              })}
              loadedLogoUrl={salon?.logoImageUrl}
              errorMessage={
                errors.logoImage &&
                t(`validation.${errors.logoImage.type}`, {
                  name: "logoImageUrl",
                  allowedTypes: "jpg, jpeg, png",
                  maxFileSize: "10MB",
                })
              }
            />

            <CoverImageField
              {...register("backgroundImage", {
                validate: {
                  maxFileSize: (value) => validateCoverImage(value),
                  allowedTypes: (value) => validateCoverImage(value),
                },
              })}
              loadedCoverUrl={salon?.backgroundImageUrl}
              errorMessage={
                errors.backgroundImage &&
                t(`validation.${errors.backgroundImage.type}`, {
                  name: "coverImageUrl",
                  allowedTypes: "jpg, jpeg, png",
                  maxFileSize: "2MB",
                })
              }
            />
          </div>
        </FormSection>

        <FormSection
          headingText={t("salonProfile.address.header")}
          descriptionText={t("salonProfile.address.description")}>
          <div className="grid gap-x-6 gap-y-6 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2">
            <TextField
              {...register("street", {
                validate: {
                  isNotWhitespace,
                },
              })}
              showLabel
              startIcon={<HomeIcon />}
              label={t("salonProfile.streetAddress.label")}
              placeholder={t("salonProfile.streetAddress.placeholder")}
            />
            <TextField
              showLabel
              {...register("streetNumber", {
                validate: {
                  isNotWhitespace,
                },
              })}
              label={t("salonProfile.streetNumber.label")}
              startIcon={<HomeIcon />}
              placeholder={t("salonProfile.streetNumber.placeholder")}
            />
            <TextField
              showLabel
              {...register("postalCode", POSTAL_CODE)}
              label={t("salonProfile.postalCode")}
              startIcon={<TicketIcon />}
              placeholder={t("placeholders.postalCode")}
              mask="99-999"
              errorMessage={
                errors.postalCode &&
                t(`validation.${errors.postalCode.type}`, {
                  name: t("salonProfile.postalCode"),
                })
              }
            />
            <TextField
              showLabel
              {...register("city", {
                validate: {
                  isNotWhitespace,
                },
              })}
              label={t("generic.city")}
              startIcon={<MapIcon />}
              placeholder={t("placeholders.city")}
            />
          </div>
        </FormSection>

        <FormSection
          headingText={t("salonProfile.contactData.header")}
          descriptionText={t("salonProfile.contactData.description")}>
          <div className="grid gap-x-6 gap-y-6 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2">
            <TextField
              showLabel
              {...register("email")}
              label={t("salonProfile.salonEmail.label")}
              startIcon={<EnvelopeIcon />}
              type="email"
              placeholder={t("salonProfile.salonEmail.placeholder")}
            />
            <PhoneField
              name="phone"
              control={control}
              label={t("salonProfile.salonPhoneNumber.label")}
              placeholder={t("generic.phone")}
              errorMessage={
                errors.phone &&
                t(`validation.${errors.phone.type}`, {
                  name: t("generic.phone"),
                })
              }
            />
          </div>
          <TextField
            showLabel
            label={t("salonProfile.linkToFacebook.label")}
            {...register("socials.facebook", {
              validate: {
                isNotWhitespace,
              },
            })}
            startIcon={<LinkIcon />}
            placeholder={t("salonProfile.linkToFacebook.placeholder")}
          />
          <TextField
            showLabel
            {...register("socials.instagram", {
              validate: {
                isNotWhitespace,
              },
            })}
            label={t("salonProfile.linkToInstagram.label")}
            startIcon={<LinkIcon />}
            placeholder={t("salonProfile.linkToInstagram.placeholder")}
          />
        </FormSection>

        <FormSection
          headingText={t("salonProfile.openingHours.header")}
          descriptionText={t("salonProfile.openingHours.description")}>
          <OpeningHours openingHours={regularOpeningHours} />
        </FormSection>

        <FormSection
          headingText={t("salonProfile.selfBooking.header")}
          descriptionText={t("salonProfile.selfBooking.description")}>
          <Switch
            control={control}
            name="isPublic"
            label={t("selfBooking.makeSalonPublic")}
            showLabel
            className="inline-flex text-sm"
            disabled={isFreePlan}
          />
          {isPublic && (
            <>
              {salon?.slug && (
                <div>
                  <Label>{t("selfBooking.linkToSalon")}</Label>
                  <div className="w-full rounded-md border border-gray-300 px-3 py-3 text-sm">
                    <a className="text-gold-600" target="_blank" href={salon.selfBooking.url}>
                      <span className="overflow-hidden text-ellipsis">{salon.selfBooking.url}</span>
                    </a>
                  </div>
                </div>
              )}
              <div className="grid gap-x-6 gap-y-6 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2">
                <TextField
                  showLabel
                  {...register("links.terms")}
                  startIcon={<LinkIcon />}
                  label={t("salonProfile.links.terms")}
                  placeholder={t("salonProfile.links.termsPlaceholder")}
                />
                <TextField
                  showLabel
                  {...register("links.privacy")}
                  label={t("salonProfile.links.privacy")}
                  startIcon={<LinkIcon />}
                  placeholder={t("salonProfile.links.privacyPlaceholder")}
                />
              </div>

              <div className="grid gap-x-6 gap-y-6 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2">
                <Controller
                  name="selfBooking.minLeadTime"
                  control={control}
                  render={({ field: { onChange, value = "" } }) => (
                    <Select
                      name="selfBooking.minLeadTime"
                      label={t("salonProfile.selfBooking.selfBookingMinLeadTime")}
                      value={value}
                      onChange={onChange}
                      options={selfBookingMinLeadTimeOptions}
                      selectBy="value"
                    />
                  )}
                />

                <Controller
                  name="selfBooking.maxLeadTime"
                  control={control}
                  render={({ field: { onChange, value = "" } }) => (
                    <Select
                      name="selfBooking.maxLeadTime"
                      label={t("salonProfile.selfBooking.selfBookingMaxLeadTime")}
                      value={value}
                      onChange={onChange}
                      options={selfBookingMaxLeadTimeOptions}
                      selectBy="value"
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="selfBooking.slotDuration"
                  render={({ field: { onChange, value = "" } }) => (
                    <Select
                      name="selfBooking.slotDuration"
                      label={t("salonProfile.selfBooking.slotDuration")}
                      value={value}
                      onChange={onChange}
                      options={slotDurationOptions}
                      selectBy="value"
                    />
                  )}
                />
              </div>

              <div className="grid gap-4">
                <Switch
                  control={control}
                  name="selfBooking.automaticalSlotApproval"
                  label={t("selfBooking.autoConfirm")}
                  showLabel
                  className="inline-flex text-sm"
                  disabled={isFreePlan}
                  onChange={(_, e) => setAutoConfirm(e)}
                />
                <Switch
                  control={control}
                  name="selfBooking.automaticalSlotApprovalSms"
                  label={t("selfBooking.autoConfirmSms")}
                  showLabel
                  className="inline-flex text-sm"
                  disabled={isFreePlan || selfBookingAutomaticalSlotApproval === false}
                />
              </div>
            </>
          )}
        </FormSection>

        <div className="border border-stone-200" />
        <Button type="submit" className="sm:ml-auto" disabled={loading || !isValid || !isDirty}>
          {t("form.saveChanges")}
        </Button>
      </form>
    </FormProvider>
  );
};
