import { useTranslation } from "react-i18next";

import { Listbox } from "@headlessui/react";

import { ChevronUpDownIcon, XMarkIcon } from "@heroicons/react/24/outline";

import { Option } from "@/utils/select-utils";
import { cn } from "@/utils/utils";

import { Button } from "../Button";

type SelectButtonProps = {
  name: string;
  selected: Option | Option[];
  multiple?: boolean;
  placeholder?: string;
  onClear?: () => void;
  disabled?: boolean;
};

export const SelectButton = ({
  name,
  selected,
  multiple = false,
  placeholder = "",
  disabled = false,
  onClear,
}: SelectButtonProps) => {
  const { t } = useTranslation();

  const renderSelected = () => {
    if (Array.isArray(selected)) {
      if (multiple && selected.length > 0) {
        return (
          <p className="line-clamp-1 text-sm text-stone-600">
            {t("generic.multiselectOptionLabel", { count: selected.length })}
          </p>
        );
      }
    } else if (selected) {
      return (
        <p className={cn(disabled ? "text-stone-400" : "text-stone-600", "line-clamp-1 text-sm")}>
          {selected.label}
        </p>
      );
    }

    return (
      <p className={cn(disabled ? "text-stone-400" : "text-stone-400", "line-clamp-1 font-light")}>
        {placeholder}
      </p>
    );
  };

  return (
    <Listbox.Button
      as="div"
      id={name}
      className={cn(
        !onClear && "pr-8",
        onClear && selected && "pr-14",
        disabled
          ? "cursor-not-allowed bg-stone-100"
          : "cursor-pointer bg-white hover:border-stone-400 focus:border-stone-400",
        "relative flex h-11 w-full appearance-none items-center rounded-md border border-stone-300 px-3 text-left text-sm  focus:outline-none focus:ring-stone-400",
      )}>
      <div className="min-h-[1.25rem]">{renderSelected()}</div>

      <div className="absolute inset-y-2 right-0 flex items-center gap-1.5 pr-2">
        {onClear && selected && (
          <Button
            variant="neutral"
            className="p-0"
            disabled={disabled}
            onClick={(e) => {
              e.preventDefault();
              onClear();
              e.stopPropagation();
            }}>
            <XMarkIcon className="h-5 w-5 text-stone-400 hover:text-stone-600" aria-hidden="true" />
          </Button>
        )}

        <span className="pointer-events-none -ml-0.5 flex items-center">
          <ChevronUpDownIcon className="h-5 w-5 text-stone-400" aria-hidden="true" />
        </span>
      </div>
    </Listbox.Button>
  );
};
