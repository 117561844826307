import { forwardRef, ForwardedRef, InputHTMLAttributes, cloneElement, KeyboardEvent } from "react";
import InputMask, { Props, ReactInputMask } from "react-input-mask"; // TODO: think about replacing it by react-text-mask

import { Icon } from "@/models";
import { cn } from "@/utils/utils";

type FieldWrapperProps = (InputHTMLAttributes<HTMLInputElement> & { mask?: undefined }) | Props;

export type TextFieldProps = FieldWrapperProps & {
  label?: string;
  name: string;
  placeholder?: string;
  className?: string;
  startIcon?: Icon;
  errorMessage?: string;
  showLabel?: boolean;
};

const FieldWrapper = forwardRef(
  ({ mask, ...delegated }: FieldWrapperProps, ref: ForwardedRef<HTMLInputElement>) => {
    return mask ? (
      <InputMask ref={ref as ForwardedRef<ReactInputMask>} mask={mask} {...delegated} />
    ) : (
      <input ref={ref} {...delegated} />
    );
  },
);

export const TextField = forwardRef(
  (
    {
      label,
      name,
      placeholder,
      className,
      startIcon,
      errorMessage,
      showLabel = true,
      ...delegated
    }: TextFieldProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const displayName = label || name;

    const handleEnter = (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key.toLowerCase() === "enter") {
        const target = event.target as HTMLInputElement;
        const { form } = target;
        if (form) {
          const index = [...form].indexOf(target);
          (form.elements[index + 1] as HTMLElement)?.focus();
          event.preventDefault();
        }
      }
    };

    return (
      <div className={cn("relative", className, showLabel && "space-y-1")}>
        <label
          htmlFor={name}
          className={cn(showLabel ? "block text-xs text-stone-500" : "sr-only")}>
          {displayName}
        </label>
        <div className={cn("relative")}>
          {startIcon && (
            <div
              className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
              aria-hidden="true">
              {cloneElement(startIcon, {
                className: cn("h-5 w-5 text-stone-400", startIcon.props.className, {
                  "text-red-600": !!errorMessage,
                }),
              })}
            </div>
          )}
          <FieldWrapper
            ref={ref}
            type="text"
            name={name}
            id={name}
            className={cn(
              "peer flex h-11 w-full appearance-none items-center rounded-md border px-3 text-sm placeholder-stone-400 placeholder:font-light focus:outline-none",
              errorMessage
                ? "border-red-600 focus:border-red-600 focus:ring-red-600"
                : "border-stone-300 focus:border-gold-600 focus:ring-gold-600",
              {
                "pl-10": !!startIcon,
                "cursor-not-allowed bg-stone-100 text-stone-400": delegated.disabled,
              },
            )}
            placeholder={placeholder}
            onKeyDown={handleEnter}
            {...delegated}
          />
        </div>
        <span className="block text-xs text-red-600">{errorMessage && errorMessage}</span>
      </div>
    );
  },
);
