import { useTranslation } from "react-i18next";

import { PencilIcon } from "@heroicons/react/24/outline";

import { Layout } from "@components/layout/Layout";
import { Subheader } from "@components/layout/subheader/Subheader";
import { Button } from "@components/ui/Button";

import { SalonProfile } from "@features/salon/components/SalonProfile";
import { SelfBooking } from "@features/salon/components/SelfBooking";

import { useSessionStore } from "@features/session/hooks";
export const SalonProfileView = () => {
  const { t } = useTranslation();
  const { permissions } = useSessionStore();

  return (
    <Layout title={t("sidebar.navigation.mySalon.children.salonProfile")}>
      <div className="-ml-4 -mr-4 mt-4 flex max-w-6xl flex-col gap-8 rounded-xl px-4 py-8 sm:mt-8 sm:px-12 sm:py-12 md:mx-auto md:px-12">
        <Subheader
          title={t("sidebar.navigation.mySalon.children.salonProfile")}
          leftSlot={
            permissions.manage_salon_profile ? (
              <Button to="/salon/edit" size="small" startIcon={<PencilIcon />}>
                {t("generic.edit")}
              </Button>
            ) : (
              <></>
            )
          }
        />
        <SalonProfile />
        <SelfBooking />
      </div>
    </Layout>
  );
};
