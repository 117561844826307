import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";

import { AppNestedProviders } from "@providers/AppNestedProviders";

import { Meta } from "@/components/Meta";
import { FlagsProvider } from "@/features/feature-flags/providers/FlagsContext";
import { ApolloClientProvider } from "@/providers/ApolloClientProvider";

import App from "./App";

import "./lib/i18n";
import "./main.css";

const rootElement = document.getElementById("root");

const root = createRoot(rootElement as Element);

root.render(
  <AppNestedProviders>
    <StrictMode>
      <HelmetProvider>
        <Meta />
        <BrowserRouter>
          <ApolloClientProvider>
            <FlagsProvider>
              <App />
            </FlagsProvider>
          </ApolloClientProvider>
        </BrowserRouter>
      </HelmetProvider>
    </StrictMode>
  </AppNestedProviders>,
);
