import { Fragment, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Listbox } from "@headlessui/react";

import { CheckIcon } from "@heroicons/react/24/outline";

import { TREATMENTS_FETCH_LIMIT } from "@features/services/hooks/use-treatments-search-store";

import { Button } from "@/components/ui/Button";
import { SelectSearch } from "@/components/ui/Select/SelectSearch";
import { Spinner } from "@/components/ui/Spinner";
import { Option, GroupedOption, getGroupedOptions } from "@/utils/select-utils";
import { cn } from "@/utils/utils";

type TreatmentSelectOptionsProps = {
  options: Option[];
  hasMore: boolean;
  loadingFetchMore: boolean;
  allTreatmentsCount: number;
  disableSearch?: boolean;
  selected: string;
  name: string;
  multiple?: boolean;
  fetchMore: (limit?: number) => void;
  onSearch?: (query: string) => void;
  selectAll?: () => void;
  deselectAll?: () => void;
};

export const TreatmentSelectOptions = ({
  options,
  selected,
  allTreatmentsCount,
  hasMore,
  loadingFetchMore,
  fetchMore,
  onSearch,
  disableSearch,
  name,
  multiple = false,
  selectAll,
  deselectAll,
}: TreatmentSelectOptionsProps) => {
  const { t } = useTranslation();

  const treatmentsLeftCount = allTreatmentsCount - options.length;

  const isOptionSelected = (option: Option) => {
    if (Array.isArray(selected)) {
      return selected.includes(option.value);
    }

    return option.value === selected;
  };

  const groupedOptions = useMemo(() => getGroupedOptions(options), [options]);

  return (
    <Listbox.Options className="mt-1 max-h-72 w-full overflow-auto rounded-md bg-white py-1 text-sm shadow-lg ring-1 ring-stone-300 focus:outline-none">
      {!disableSearch && onSearch && (
        <SelectSearch
          name={name}
          placeholder={t("actions.searchTreatment")}
          onSearch={onSearch}
          noResult={options.length === 0}
        />
      )}
      <div className="mt-1">
        <div className="flex flex-1 select-none justify-start gap-2 bg-stone-100 p-3">
          <Button
            size="tiny"
            variant="secondary-outline"
            disabled={options.length === selected.length}
            onClick={selectAll}>
            {t("actions.selectAll")}
          </Button>
          <Button
            size="tiny"
            variant="secondary-outline"
            disabled={selected.length === 0}
            onClick={deselectAll}>
            {t("actions.deselectAll")}
          </Button>
        </div>
        {groupedOptions.map((group: GroupedOption) => {
          return (
            <div key={group.groupValue}>
              <div className={`bg-stone-100 px-4 py-1`}>
                <span className={`text-[11px] font-semibold uppercase text-stone-900`}>
                  {group.groupLabel}
                </span>
              </div>
              {group.options.map((option: Option) => (
                <Listbox.Option key={option.value} value={option.value} as={Fragment}>
                  {({ active }) => (
                    <li
                      className={cn(
                        active && `bg-stone-50 text-stone-900`,
                        !active && isOptionSelected(option) && `bg-stone-100 text-stone-900`,
                        `relative flex cursor-pointer select-none gap-4 px-4 py-3 text-stone-600`,
                      )}>
                      {multiple && (
                        <div
                          className={cn(
                            isOptionSelected(option)
                              ? `cursor-pointer border-gold-600 bg-gold-500 text-white`
                              : "cursor-pointer border-stone-400 bg-white hover:border-stone-500",
                            "h-5 w-5 shrink-0 rounded border p-[1px] shadow-sm transition-colors",
                          )}>
                          {isOptionSelected(option) && <CheckIcon />}
                        </div>
                      )}
                      <div>
                        <p>{option.label}</p>
                      </div>
                    </li>
                  )}
                </Listbox.Option>
              ))}
            </div>
          );
        })}
      </div>
      {hasMore && !loadingFetchMore && (
        <div className="flex flex-wrap justify-start gap-2 bg-stone-100 px-3 py-4">
          <Button onClick={() => fetchMore()} variant="primary" size="tiny" className="grow">
            {t("actions.loadMore", {
              count:
                TREATMENTS_FETCH_LIMIT > treatmentsLeftCount
                  ? treatmentsLeftCount
                  : TREATMENTS_FETCH_LIMIT,
            })}
          </Button>
          <Button
            onClick={() => fetchMore(treatmentsLeftCount)}
            variant="primary-outline"
            size="tiny"
            className="grow">
            {t("actions.loadAll", {
              count: treatmentsLeftCount,
            })}
          </Button>
        </div>
      )}
      {loadingFetchMore && (
        <div className="flex justify-center py-4">
          <Spinner className="h-8 w-8" />
        </div>
      )}
    </Listbox.Options>
  );
};
