import { cloneElement, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";

import { Layout } from "@/components/layout/Layout";
import { Subheader } from "@/components/layout/subheader/Subheader";
import { Button } from "@/components/ui/Button";
import { DetailsTable } from "@/features/payments/components/DetailsTable";
import { Plans } from "@/features/payments/components/Plans";
import { SubscriptionCTA } from "@/features/payments/components/SubscriptionCTA";
import { usePaymentsStore } from "@/features/payments/hooks";
import { useProductsStore } from "@/features/payments/hooks/use-products-store";
import type { FeaturesGroups, Plan } from "@/features/payments/models";
import { useSessionStore } from "@/features/session/hooks/use-session-store";
import { Icon } from "@/models";
import { useToasts } from "@/providers/ToastsProvider";
import { SubscriptionPlan } from "@/types";
import { sendEvent } from "@/utils/google-analytics";

export const SubscriptionsView = () => {
  const { t } = useTranslation();
  const { showToast } = useToasts();
  const { salonPlan, trialUsed, isFreePlan, session } = useSessionStore();
  const { orderSalonPlan, loading: loadingProducts } = useProductsStore();
  const { loading: loadingPayments, createBillingPortalSession } = usePaymentsStore();

  const loading = loadingProducts || loadingPayments.loadingBillingPortalSession;

  const handleManageClick = async () => {
    if (!session?.stripeCustomerId) return;

    const { data, errors } = await createBillingPortalSession(session.stripeCustomerId);

    if (errors) {
      showToast({
        type: "error",
        title: t("generic.oops"),
        description: t("generic.somethingWentWrong"),
      });
    } else if (data?.createBillingPortalSession?.url) {
      window.location.href = data.createBillingPortalSession.url;
    }
  };

  const featuresGroups: FeaturesGroups = useMemo(
    () => [
      {
        category: {
          title: t("pricing.groups.management.title"),
        },
        features: [
          {
            title: t("pricing.features.clients.title"),
            description: t("pricing.features.clients.description"),
            tiers: [
              {
                title: "starter",
                value: t("pricing.noLimit"),
              },
              {
                title: "salon",
                value: t("pricing.noLimit"),
              },
            ],
          },
          {
            title: t("pricing.features.treatments.title"),
            description: t("pricing.features.treatments.description"),
            tiers: [
              {
                title: "starter",
                value: t("pricing.noLimit"),
              },
              {
                title: "salon",
                value: t("pricing.noLimit"),
              },
            ],
          },
          {
            title: t("pricing.features.appointments.title"),
            tiers: [
              {
                title: "starter",
                value: "50",
              },
              {
                title: "salon",
                value: t("pricing.noLimit"),
              },
            ],
          },
          {
            title: t("pricing.features.employees.title"),
            description: t("pricing.features.employees.description"),
            tiers: [
              {
                title: "starter",
                value: (
                  <div className="text-red-400">
                    <FeatureIcon icon={<XMarkIcon />} />
                  </div>
                ),
              },
              {
                title: "salon",
                value: "6",
              },
            ],
          },
          {
            title: t("pricing.features.calendar.title"),
            description: t("pricing.features.calendar.description"),
            tiers: [
              {
                title: "starter",
                value: (
                  <div className="text-pearl-500">
                    <FeatureIcon icon={<CheckIcon />} />
                  </div>
                ),
              },
              {
                title: "salon",
                value: (
                  <div className="text-pearl-500">
                    <FeatureIcon icon={<CheckIcon />} />
                  </div>
                ),
              },
            ],
          },
          {
            title: t("pricing.features.statistics.title"),
            description: t("pricing.features.statistics.description"),
            tiers: [
              {
                title: "starter",
                value: (
                  <div className="text-pearl-500">
                    <FeatureIcon icon={<CheckIcon />} />
                  </div>
                ),
              },
              {
                title: "salon",
                value: (
                  <div className="text-pearl-500">
                    <FeatureIcon icon={<CheckIcon />} />
                  </div>
                ),
              },
            ],
          },
          {
            title: t("pricing.features.selfBooking.title"),
            description: t("pricing.features.selfBooking.description"),
            tiers: [
              {
                title: "starter",
                value: (
                  <div className="text-red-400">
                    <FeatureIcon icon={<XMarkIcon />} />
                  </div>
                ),
              },
              {
                title: "salon",
                value: (
                  <div className="text-pearl-500">
                    <FeatureIcon icon={<CheckIcon />} />
                  </div>
                ),
              },
            ],
          },
        ],
      },
      {
        category: {
          title: t("pricing.groups.forClients.title"),
        },
        features: [
          {
            title: t("pricing.features.smsCanceling.title"),
            description: t("pricing.features.smsCanceling.description"),
            tiers: [
              {
                title: "starter",
                value: (
                  <div className="text-pearl-500">
                    <FeatureIcon icon={<CheckIcon />} />
                  </div>
                ),
              },
              {
                title: "salon",
                value: (
                  <div className="text-pearl-500">
                    <FeatureIcon icon={<CheckIcon />} />
                  </div>
                ),
              },
            ],
          },
          {
            title: t("pricing.features.smsNotifications.title"),
            description: t("pricing.features.smsNotifications.description"),
            tiers: [
              {
                title: "starter",
                value: (
                  <div className="text-pearl-500">
                    <FeatureIcon icon={<CheckIcon />} />
                  </div>
                ),
              },
              {
                title: "salon",
                value: (
                  <div className="text-pearl-500">
                    <FeatureIcon icon={<CheckIcon />} />
                  </div>
                ),
              },
            ],
          },
        ],
      },
      {
        category: {
          title: t("pricing.groups.branding.title"),
        },
        features: [
          {
            title: t("pricing.features.smsPrice.title"),
            tiers: [
              {
                title: "starter",
                value: "19 gr",
              },
              {
                title: "salon",
                value: "12 gr",
              },
            ],
          },
          {
            title: t("pricing.features.senderName.title"),
            description: t("pricing.features.senderName.description"),
            tiers: [
              {
                title: "starter",
                value: (
                  <div className="text-red-400">
                    <FeatureIcon icon={<XMarkIcon />} />
                  </div>
                ),
              },
              {
                title: "salon",
                value: (
                  <div className="text-pearl-500">
                    <FeatureIcon icon={<CheckIcon />} />
                  </div>
                ),
              },
            ],
          },
          {
            title: t("pricing.features.smsTemplates.title"),
            description: t("pricing.features.smsTemplates.description"),
            tiers: [
              {
                title: "starter",
                value: (
                  <div className="text-red-400">
                    <FeatureIcon icon={<XMarkIcon />} />
                  </div>
                ),
              },
              {
                title: "salon",
                value: (
                  <div className="text-pearl-500">
                    <FeatureIcon icon={<CheckIcon />} />
                  </div>
                ),
              },
            ],
          },
        ],
      },
      {
        category: {
          title: t("pricing.groups.comfort.title"),
        },
        features: [
          {
            title: t("pricing.features.talks.title"),
            description: t("pricing.features.talks.description"),
            tiers: [
              {
                title: "starter",
                value: (
                  <div className="text-pearl-500">
                    <FeatureIcon icon={<CheckIcon />} />
                  </div>
                ),
              },
              {
                title: "salon",
                value: t("pricing.features.talks.values.priority"),
              },
            ],
          },
          {
            title: t("pricing.features.gdpr.title"),
            tiers: [
              {
                title: "starter",
                value: (
                  <div className="text-pearl-500">
                    <FeatureIcon icon={<CheckIcon />} />
                  </div>
                ),
              },
              {
                title: "salon",
                value: (
                  <div className="text-pearl-500">
                    <FeatureIcon icon={<CheckIcon />} />
                  </div>
                ),
              },
            ],
          },
          {
            title: t("pricing.features.guaranteedPrice.title"),
            description: t("pricing.features.guaranteedPrice.description"),
            tiers: [
              {
                title: "starter",
                value: (
                  <div className="text-pearl-500">
                    <FeatureIcon icon={<CheckIcon />} />
                  </div>
                ),
              },
              {
                title: "salon",
                value: (
                  <div className="text-pearl-500">
                    <FeatureIcon icon={<CheckIcon />} />
                  </div>
                ),
              },
            ],
          },
          {
            title: t("pricing.features.privacy.title"),
            description: t("pricing.features.privacy.description"),
            tiers: [
              {
                title: "starter",
                value: (
                  <div className="text-pearl-500">
                    <FeatureIcon icon={<CheckIcon />} />
                  </div>
                ),
              },
              {
                title: "salon",
                value: (
                  <div className="text-pearl-500">
                    <FeatureIcon icon={<CheckIcon />} />
                  </div>
                ),
              },
            ],
          },
          {
            title: t("pricing.features.simplicity.title"),
            description: t("pricing.features.simplicity.description"),
            tiers: [
              {
                title: "starter",
                value: (
                  <div className="text-pearl-500">
                    <FeatureIcon icon={<CheckIcon />} />
                  </div>
                ),
              },
              {
                title: "salon",
                value: (
                  <div className="text-pearl-500">
                    <FeatureIcon icon={<CheckIcon />} />
                  </div>
                ),
              },
            ],
          },
          {
            title: t("pricing.features.fees.title"),
            description: t("pricing.features.fees.description"),
            tiers: [
              {
                title: "starter",
                value: "0%",
              },
              {
                title: "salon",
                value: "0%",
              },
            ],
          },
          {
            title: t("pricing.features.creditCard.title"),
            description: t("pricing.features.creditCard.description"),
            tiers: [
              {
                title: "starter",
                value: t("pricing.features.notRequired"),
              },
              {
                title: "salon",
                value: t("pricing.features.notRequired"),
              },
            ],
          },
        ],
      },
    ],
    [t],
  );

  const plans: Plan[] = useMemo(
    () => [
      {
        name: t("pricing.tiers.starter.name"),
        value: SubscriptionPlan.Free,
        compared: true,
        description: t("pricing.tiers.starter.description"),
        price: { monthly: "0 zł", annually: "0 zł" },
        netPrice: { monthly: "", annually: "" },
        mainFeatures: [
          { id: 0, value: t("pricing.tiers.starter.mainFeatures.0") },
          { id: 1, value: t("pricing.tiers.starter.mainFeatures.1") },
          { id: 2, value: t("pricing.tiers.starter.mainFeatures.2") },
        ],
      },
      {
        name: t("pricing.tiers.salon.name"),
        value: SubscriptionPlan.Salon,
        compared: true,
        description: t("pricing.tiers.salon.description"),
        price: {
          monthly: `99 zł / ${t("generic.monthAbbreviation")}`,
          annually: `990 zł / ${t("generic.year")}`,
        },
        netPrice: {
          monthly: `80,49 zł ${t("generic.net")}`,
          annually: `804,88 zł ${t("generic.net")}`,
        },
        button: salonPlan !== SubscriptionPlan.Salon && (
          <Button
            onClick={orderSalonPlan}
            loading={loading}
            variant="primary"
            className="mt-4 w-full md:w-auto">
            {trialUsed ? t("subscriptions.upgrade") : t("subscriptions.cta.generic.startTrial")}
          </Button>
        ),
        mainFeatures: [
          { id: 0, value: t("pricing.tiers.salon.mainFeatures.0") },
          { id: 1, value: t("pricing.tiers.salon.mainFeatures.1") },
          { id: 2, value: t("pricing.tiers.salon.mainFeatures.2") },
          { id: 3, value: t("pricing.tiers.salon.mainFeatures.3") },
          { id: 5, value: t("pricing.tiers.salon.mainFeatures.5") },
          { id: 4, value: t("pricing.tiers.salon.mainFeatures.4") },
        ],
      },
    ],
    [t, salonPlan, trialUsed, orderSalonPlan],
  );

  return (
    <Layout title={t("subscriptions.title")}>
      <div className="-ml-4 -mr-4 mt-4 flex max-w-6xl flex-col gap-8 rounded-xl px-4 py-8 sm:mt-8 sm:px-12 sm:py-12 md:mx-auto md:px-12">
        <Subheader
          title={t("subscriptions.title")}
          leftSlot={
            session?.stripeCustomerId ? (
              <Button size="small" disabled={loading} onClick={handleManageClick}>
                {t("subscriptions.manage")}
              </Button>
            ) : (
              <></>
            )
          }
        />
        <div className="text-center">
          <h2 id="pricing-title" className="text-3xl font-medium tracking-tight text-stone-900">
            {t("pricing.title")}
          </h2>
          <p className="m-auto mt-2 max-w-2xl text-lg text-stone-600">{t("pricing.description")}</p>
        </div>
        <Plans plans={plans} />
        <DetailsTable featuresGroups={featuresGroups} plans={plans} />
        <div className="pb-16">
          {isFreePlan && !trialUsed && (
            <SubscriptionCTA
              heading={t("subscriptions.cta.generic.heading")}
              description={t("subscriptions.cta.generic.description")}
              buttonText={t("subscriptions.cta.generic.startTrial")}
              onClick={() => {
                sendEvent("free_trial_click", "payments", "subscriptions");
                orderSalonPlan();
              }}
            />
          )}
        </div>
      </div>
    </Layout>
  );
};

const FeatureIcon = ({ icon }: { icon: Icon }) =>
  cloneElement(icon, { className: "mx-auto h-5 w-5" });
