import { ReactNode } from "react";

import { ArrowLeftIcon } from "@heroicons/react/24/outline";

import { Button } from "@components/ui/Button";

type SubheaderProps = {
  title: string;
  backUrl?: string;
  leftSlot?: ReactNode;
  rightSlot?: ReactNode;
  as?: "h1" | "h2";
};

export const Subheader = ({ title, backUrl, leftSlot, rightSlot, as = "h1" }: SubheaderProps) => {
  return (
    <div className="flex items-center justify-between gap-x-2 md:h-full md:w-full">
      <div className="flex flex-wrap items-center gap-x-4 gap-y-4">
        {backUrl && (
          <Button variant="neutral" to={backUrl}>
            <ArrowLeftIcon className="size-6 text-stone-500" />
          </Button>
        )}
        {as === "h1" && (
          <h1 className="text-2xl font-semibold leading-6 text-stone-700">{title}</h1>
        )}
        {as === "h2" && <h2 className="text-xl font-medium text-stone-700">{title}</h2>}
        {leftSlot && <div className="flex gap-x-2">{leftSlot}</div>}
      </div>
      {rightSlot && <div className="flex items-end gap-x-2">{rightSlot}</div>}
    </div>
  );
};
