import { createContext, ReactNode, useContext } from "react";

import { FeatureFlag } from "@/types";

import { FEATURE_FLAG_NAMES, FeatureFlagName } from "../constants";
import { useFeatureFlagsQuery } from "../queries/FeatureFlags.generated";

const FlagsContext = createContext({
  flags: [] as FeatureFlag[],
  loading: false,
});

export const FlagsProvider = ({ children }: { children: ReactNode }) => {
  const { data, loading } = useFeatureFlagsQuery({
    variables: { names: [...FEATURE_FLAG_NAMES] },
    skip: !FEATURE_FLAG_NAMES.length,
  });

  return (
    <FlagsContext.Provider value={{ flags: data?.featureFlags || [], loading }}>
      {children}
    </FlagsContext.Provider>
  );
};

export const useFlags = () => useContext(FlagsContext);

export const useFlag = (name: FeatureFlagName) => {
  const { flags, loading } = useFlags();

  return { enabled: flags.find((flag) => flag.name === name)?.enabled || false, loading };
};
