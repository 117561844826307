import { ReactNode } from "react";

import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";

import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { Button } from "@components/ui/Button";

import { useSessionStore } from "@/features/session/hooks";
import { HeadCategory } from "@/types";

type HeadCategoryListItemProps = {
  headCategory: HeadCategory;
  selectedUuid?: string;
  renderHeadCategory: (headCategory: HeadCategory) => ReactNode;
  onEdit?: (headCategory: HeadCategory) => void;
  onRemove?: (headCategory: HeadCategory) => void;
  onSelect?: (headCategory: HeadCategory) => void;
  isDraggable?: boolean;
};

export const HeadCategoryListItem = ({
  headCategory,
  renderHeadCategory,
  onEdit,
  onRemove,
  onSelect,
  isDraggable,
}: HeadCategoryListItemProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: headCategory.uuid,
    disabled: !isDraggable,
  });
  const { permissions } = useSessionStore();

  return (
    <li
      key={headCategory.uuid}
      ref={setNodeRef}
      style={{
        transform: CSS.Transform.toString(transform),
        transition,
        touchAction: "none",
      }}
      {...attributes}
      {...listeners}
      className={
        "group grid grid-cols-[1fr_2.5rem_2.5rem] gap-1 bg-transparent bg-white text-stone-500 transition-colors hover:bg-stone-100"
      }>
      <Button
        variant="neutral"
        className="w-full truncate p-2 text-left"
        onClick={() => onSelect && !isDraggable && onSelect(headCategory)}>
        {renderHeadCategory(headCategory)}
      </Button>
      <>
        {onRemove && !isDraggable && permissions.manage_treatments && (
          <Button
            variant="danger-inline"
            className="h-full w-10 transition-opacity hover:bg-stone-300 group-hover:opacity-100 lg:opacity-0"
            disabled={!permissions.manage_treatments}
            onClick={() => onRemove(headCategory)}>
            <TrashIcon className="h-5 w-5" />
          </Button>
        )}
        {onEdit && !isDraggable && permissions.manage_treatments && (
          <Button
            variant="primary-inline"
            className="h-full w-10 transition-opacity hover:bg-stone-300 group-hover:opacity-100 lg:opacity-0"
            disabled={!permissions.manage_treatments}
            onClick={() => onEdit(headCategory)}>
            <PencilIcon className="h-5 w-5" />
          </Button>
        )}
      </>
    </li>
  );
};
