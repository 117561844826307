import { Fragment, ReactNode } from "react";

import { Dialog, Transition } from "@headlessui/react";

import { XMarkIcon } from "@heroicons/react/24/outline";

import { cn } from "@/utils/utils";

type DefaultDialogProps = {
  open: boolean;
  title: string | ReactNode;
  children: ReactNode;
  maxHeight?: boolean;
  onClose: () => void;
};

export const DefaultDialog = ({
  open,
  title,
  children,
  onClose,
  maxHeight = false,
}: DefaultDialogProps) => {
  return (
    <Transition.Root appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-stone-600/50" />
        </Transition.Child>

        <div className="fixed inset-0 z-40 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center text-center md:p-4">
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-100"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Dialog.Panel className="flex min-h-screen w-screen flex-1 transform flex-col overflow-hidden bg-stone-50 p-4 pt-0 text-left align-middle shadow-xl transition-all md:min-h-fit md:max-w-xl md:rounded-lg md:px-8 md:pb-6">
                <div className="relative flex flex-1 flex-col">
                  <Dialog.Title
                    as="h4"
                    className="relative -mx-4 bg-stone-200/70 px-4 py-3 text-sm uppercase leading-6 text-stone-500 md:-mx-8 md:px-8 md:py-3">
                    <span>{title}</span>
                    <XMarkIcon
                      className="absolute right-4 top-3 h-5 w-5 cursor-pointer text-stone-500 md:right-8 md:top-3.5"
                      onClick={onClose}
                    />
                  </Dialog.Title>
                  <div
                    className={cn(
                      maxHeight && "md:max-h-[500px] md:scroll-py-3 md:overflow-y-auto",
                      "mt-2 flex flex-1 flex-col",
                    )}>
                    {children}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
