import { FINALIZED_COLOR } from "@features/calendar/constants";

import { Category } from "@/types";
import { resolveCategoryColor } from "@/utils/colors";
import { cn } from "@/utils/utils";

type CategoryEntityProps = {
  entity: Category;
};

export const CategoryEntity = ({ entity }: CategoryEntityProps) => {
  const color = resolveCategoryColor(entity.color) || FINALIZED_COLOR;

  return (
    <div className="flex items-center gap-3">
      <div className={cn("h-6 w-6 rounded-full border", color.primary, color.border)}></div>
      {entity.name}
    </div>
  );
};
