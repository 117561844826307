import { lazy, Suspense, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { Transition } from "@headlessui/react";

import { useAppointmentStore } from "@/features/calendar/hooks/use-appointment-store";
import { useCalendarStore } from "@/features/calendar/hooks/use-calendar-store";
import { useBreakpoint } from "@/hooks/use-breakpoint";

import { useCalendarDate } from "../contexts/CalendarDateContext";
import { useCalendarDialogs } from "../contexts/CalendarDialogContext";
import { useCalendarFilters } from "../contexts/CalendarFiltersContext";
import { useCalendarView } from "../contexts/CalendarViewContext";
import { CalendarViews } from "../models";
import { convertCalendarItemsToSlotsFormat } from "../utils/slots";
import { CalendarLoader } from "./CalendarLoader";

const CalendarDayContent = lazy(() => import("./CalendarDay/CalendarDayContent"));
const CalendarWeekContent = lazy(() => import("./CalendarWeek/CalendarWeekContent"));
const CalendarEmployeeWeekContent = lazy(
  () => import("./CalendarEmployeeWeek/CalendarEmployeeWeekContent"),
);
const CalendarMonthContent = lazy(() => import("./CalendarMonth/CalendarMonthContent"));

const useHandleAppointmentSelection = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [appointmentUuid, setAppointmentUuid] = useState<string>();
  const { appointment } = useAppointmentStore({ id: appointmentUuid });
  const { selectAppointment } = useCalendarDialogs();

  useEffect(() => {
    const appointmentUuid = searchParams.get("appointmentUuid");

    if (appointmentUuid) {
      setAppointmentUuid(appointmentUuid);
      setSearchParams({});
    }
  }, [searchParams]);

  useEffect(() => {
    if (appointment) {
      const slot = convertCalendarItemsToSlotsFormat([appointment])[0];

      if (slot) {
        selectAppointment(slot);
      }
    }
  }, [appointment]);
};

export const CalendarContent = () => {
  const { isSm } = useBreakpoint("sm");
  const { view } = useCalendarView();
  const { timeRange } = useCalendarDate();
  const { filters } = useCalendarFilters();

  const { slotRowsByEmployee } = useCalendarStore({
    filters: { timeRange, ...filters },
    calendarView: view,
  });

  useHandleAppointmentSelection();

  const renderContent = () => {
    return (
      <Suspense fallback={<CalendarLoader />}>
        {view === CalendarViews.Day || view === CalendarViews.EmployeeDay ? (
          <CalendarDayContent />
        ) : view === CalendarViews.Week ? (
          isSm ? (
            slotRowsByEmployee.length > 1 ? (
              <CalendarWeekContent />
            ) : (
              <CalendarEmployeeWeekContent />
            )
          ) : (
            <CalendarDayContent />
          )
        ) : view === CalendarViews.EmployeeWeek ? (
          isSm ? (
            <CalendarEmployeeWeekContent />
          ) : (
            <CalendarDayContent />
          )
        ) : view === CalendarViews.Month ? (
          <CalendarMonthContent />
        ) : null}
      </Suspense>
    );
  };

  return (
    <div className="relative -mx-2 -mb-4 xs:-mx-4 sm:mx-0 sm:mb-0">
      <Transition
        show={true}
        appear
        as="div"
        enter="transition-opacity duration-300 delay-100 absolute"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className="h-full w-full">
        {renderContent()}
      </Transition>
    </div>
  );
};
