import { useEffect, useMemo } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Navigate, useNavigate, useParams } from "react-router-dom";

import { Transition } from "@headlessui/react";

import { UserIcon } from "@heroicons/react/24/outline";

import { ConfirmationDialog } from "@components/dialogs/ConfirmationDialog";
import { Subheader } from "@components/layout/subheader/Subheader";
import { Meta } from "@components/Meta";
import { Button } from "@components/ui/Button";
import { PhoneField } from "@components/ui/PhoneField";
import { Spinner } from "@components/ui/Spinner";
import { TextField } from "@components/ui/TextField";

import { useConfirmationDialog } from "@hooks/use-confirmation-dialog";

import { useToasts } from "@providers/ToastsProvider";

import { useEmployeesStore, useEmployeeStore } from "@features/employees/hooks";

import { FormSection } from "@/components/layout/FormSection";
import { Switch } from "@/components/ui/Switch";
import { NAME } from "@/constants/validations";
import { ImagesUploader } from "@/features/calendar/components/Appointment/ImagesUploader";
import { TreatmentSelect } from "@/features/services/components/treatments/TreatmentSelect";
import { useSessionStore } from "@/features/session/hooks";
import { useAwsStore } from "@/hooks/use-aws-store";
import { useBreakpoint } from "@/hooks/use-breakpoint";
import { Employee, UpdateEmployeeInput } from "@/types";
import { removeImageFromS3, uploadImageToS3 } from "@/utils/s3-utils";
import { capitalize } from "@/utils/utils";

import { EmployeeSchedule } from "../models";
import { EmployeePermissions } from "./EmployeePermissions";
import { EmployeeWorkSchedule } from "./EmployeeWorkSchedule";

type EmployeeFormProps = {
  employee: Employee;
  workSchedule: EmployeeSchedule;
  children?: React.ReactNode;
};

const EmployeeForm = ({ employee, workSchedule, children }: EmployeeFormProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { showToast } = useToasts();
  const { loadingUpdate, updateError, updateEmployee } = useEmployeeStore();
  const { isPossibleToDisableEmployeeAvailability } = useEmployeesStore();
  const { isOwner, session, isSalonPlan, permissions } = useSessionStore();
  const {
    addImages,
    removeImage,
    images,
    imagesErrors,
    setImagesErrors,
    getServerImagesSize,
    setServerImageToRemove,
    loadServerImages,
    currentImagesUrls,
  } = useAwsStore();

  const formMethods = useForm<UpdateEmployeeInput>({
    mode: "all",
    shouldUnregister: true,
    defaultValues: async () => ({
      ...employee,
      treatmentsUuids: employee.treatments.map(({ uuid }) => uuid),
      permissions: JSON.parse(employee.permissions ?? "{}"),
      imageUrl: employee.imageUrl,
    }),
  });

  const {
    register,
    formState: { errors, isValid, isDirty, dirtyFields },
    control,
    reset,
    setValue,
    handleSubmit,
  } = formMethods;

  const imagesToRemove = useMemo(() => {
    return images.filter((image) => image.toRemove);
  }, [images]);

  const imagesToUpload = useMemo(() => {
    return images.filter((image) => !image.isServerImage);
  }, [images]);

  useEffect(() => {
    const serverImages = employee.imageUrl ? [employee.imageUrl] : [];

    if (serverImages.length > 0) {
      loadServerImages(serverImages);
    }
  }, [employee.imageUrl]);

  useEffect(() => {
    reset({
      ...employee,
      treatmentsUuids: employee.treatments.map(({ uuid }) => uuid),
    });
  }, [employee]);

  useEffect(() => {
    setValue("imageUrl", currentImagesUrls[0] || null, { shouldDirty: true });
  }, [currentImagesUrls.length]);

  const handleUpdateEmployee: SubmitHandler<UpdateEmployeeInput> = async (data) => {
    const serializedData = {
      ...data,
      permissions: JSON.stringify(data.permissions),
    };

    try {
      if (imagesToUpload.length > 0) {
        await Promise.all(
          imagesToUpload.map((image) => {
            return uploadImageToS3(image.file, image.name);
          }),
        );
      }
    } catch (error) {
      console.error("Error while uploading images", error);
    }

    try {
      if (imagesToRemove.length > 0) {
        await Promise.all(imagesToRemove.map((image) => removeImageFromS3(image.name)));
      }
    } catch (error) {
      console.error("Error while removing images", error);
    }

    const { errors } = await updateEmployee(serializedData, employee.accountUuid);

    if (!errors) {
      showToast({
        type: "success",
        title: t("employees.updateToast.title"),
        description: t("employees.updateToast.message", {
          employee: `${data.firstName} ${data.lastName}`,
        }),
      });
      navigate("/employees");
    }
  };

  const isAvailableForAppointments = formMethods.watch("isAvailableForAppointments") as boolean;

  const isAvailableForAppointmentsSwitchDisabled =
    !isPossibleToDisableEmployeeAvailability &&
    isAvailableForAppointments &&
    !dirtyFields.isAvailableForAppointments;

  return (
    <FormProvider {...formMethods}>
      <form
        className="grid gap-2 divide-y-[1px] @container xl:gap-8"
        onSubmit={handleSubmit(handleUpdateEmployee)}
        noValidate>
        <FormSection
          headingText={t("employees.employeeProfile.generalInfo.header")}
          descriptionText={t("employees.employeeProfile.generalInfo.description")}>
          <div className="grid gap-x-6 gap-y-6 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2">
            <TextField
              {...register("firstName", {
                onChange(event) {
                  const { value } = event.target;

                  const capitalized = capitalize(value);

                  event.target.value = capitalized.trim();
                },
                required: true,
                minLength: NAME.MIN_LENGTH,
                maxLength: NAME.MAX_LENGTH,
              })}
              label={t("generic.firstName")}
              showLabel
              placeholder={t("generic.firstName")}
              startIcon={<UserIcon />}
              errorMessage={
                errors.firstName &&
                t(`validation.${errors.firstName.type}`, {
                  name: t("generic.firstName"),
                  minLength: NAME.MIN_LENGTH,
                  maxLength: NAME.MAX_LENGTH,
                })
              }
            />
            <TextField
              {...register("lastName", {
                onChange(event) {
                  const { value } = event.target;

                  const capitalized = capitalize(value);

                  event.target.value = capitalized.trim();
                },
                required: true,
                minLength: NAME.MIN_LENGTH,
                maxLength: NAME.MAX_LENGTH,
              })}
              label={t("generic.lastName")}
              showLabel
              placeholder={t("generic.lastName")}
              startIcon={<UserIcon />}
              errorMessage={
                errors.lastName &&
                t(`validation.${errors.lastName.type}`, {
                  name: t("generic.lastName"),
                  minLength: NAME.MIN_LENGTH,
                  maxLength: NAME.MAX_LENGTH,
                })
              }
            />
          </div>
          <PhoneField
            name="phone"
            control={control}
            label={t("generic.phone")}
            placeholder={t("generic.phone")}
            className="h-11"
            errorMessage={
              errors.phone &&
              t(`validation.${errors.phone.type}`, {
                name: t("generic.phone"),
              })
            }
          />

          <div className="sm:col-span-6">
            <label className="block text-xs text-stone-500">{t("generic.image")}</label>
            <ImagesUploader
              images={images}
              imagesErrors={imagesErrors}
              addImages={addImages}
              removeImage={removeImage}
              setImagesErrors={setImagesErrors}
              handleRemoveFromServer={setServerImageToRemove}
              getServerImagesSize={getServerImagesSize}
              multiple={false}
              showGalleryLabel={false}
              acceptableImageSizeInMB="2"
              className="aspect-square"
            />
          </div>
        </FormSection>
        <FormSection
          headingText={t("employees.employeeProfile.treatments.header")}
          descriptionText={t("employees.employeeProfile.treatments.description")}>
          <TreatmentSelect
            control={control}
            name="treatmentsUuids"
            label={t("employees.treatmentsThatEmployeeHasAbilityToDo")}
            multiple
          />
        </FormSection>
        {isOwner && isSalonPlan && (
          <FormSection
            headingText={t("employees.employeeProfile.isAvailableForAppoitments.header")}
            descriptionText={t("employees.employeeProfile.isAvailableForAppoitments.description")}>
            <div className="flex items-center gap-4">
              <Switch
                name="isAvailableForAppointments"
                control={control}
                className="flex justify-between text-sm text-stone-500"
                label="isAvailableForAppointments"
                disabled={isAvailableForAppointmentsSwitchDisabled}
              />
              <span className="text-sm text-stone-900">
                {isAvailableForAppointments
                  ? t("employees.employeeProfile.isAvailableForAppoitments.labelActive")
                  : t("employees.employeeProfile.isAvailableForAppoitments.labelInactive")}
              </span>
            </div>
          </FormSection>
        )}
        <FormSection
          headingText={t("employees.employeeProfile.workSchedule.header")}
          descriptionText={t("employees.employeeProfile.workSchedule.description")}>
          <EmployeeWorkSchedule workSchedule={workSchedule} />
        </FormSection>
        {/* An owner never should be able to remove his own account from this place */}
        {employee.accountUuid !== session?.accountUuid && (
          <>
            {isOwner && (
              <FormSection
                headingText={t("employees.employeeProfile.permissions.header")}
                descriptionText={t("employees.employeeProfile.permissions.description")}>
                <EmployeePermissions />
              </FormSection>
            )}
            {((employee.role === "EMPLOYEE" && permissions.delete_employee) || isOwner) && (
              <FormSection
                headingText={t("employees.delete")}
                descriptionText={t("employees.deleteDescription")}>
                {children}
              </FormSection>
            )}
          </>
        )}
        {updateError && <p className="my-4 text-red-500">{updateError.message}</p>}
        <div className="flex w-full flex-auto flex-col justify-end gap-4 pt-8 sm:flex-row">
          <Button
            variant="primary-outline"
            disabled={loadingUpdate}
            to="/employees"
            className="w-full sm:w-48">
            {t("generic.cancel")}
          </Button>
          <Button
            type="submit"
            variant="primary"
            className="h-[42px] w-full sm:w-48"
            disabled={loadingUpdate || !isValid || !isDirty || Object.keys(errors).length > 0}>
            {t("generic.save")}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

export const EmployeeEdit = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { showToast } = useToasts();
  const { id } = useParams();
  const { isMd } = useBreakpoint("md");
  const { employee, employeeWorkSchedule, loading, fetchError, deleteEmployee } =
    useEmployeeStore(id);

  const confirmationDialogState = useConfirmationDialog();

  const handleEmployeeRemove = () => {
    id &&
      confirmationDialogState.open({
        title: t("generic.areYouSure"),
        message: t("employees.deleteEmployeePrompt"),
        onConfirm: async () => {
          const { errors } = await deleteEmployee(id);
          if (errors) {
            showToast({
              type: "error",
              title: t("generic.oops"),
              description: t("generic.somethingWentWrong"),
            });
          } else {
            navigate("/employees");
          }
        },
      });
  };

  return fetchError ? (
    <Navigate to="/employees" />
  ) : (
    <>
      <Meta title={t("employees.edit")} />
      <div className="sm:mb-4 md:mx-auto">
        <Subheader
          title={`${employee?.firstName} ${employee?.lastName}`}
          backUrl={isMd ? undefined : `/employees`}
        />
      </div>
      <Transition
        show={loading}
        as="div"
        enter="transition-opacity duration-500"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-500"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className="flex justify-center p-10">
        <Spinner className="h-8 w-8" />
      </Transition>
      <Transition
        show={!loading}
        appear
        as="div"
        enter="transition-opacity duration-500"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-500"
        leaveFrom="opacity-100"
        leaveTo="opacity-0">
        {employee ? (
          <EmployeeForm employee={employee} workSchedule={employeeWorkSchedule}>
            <Button className="max-w-xs" variant="danger" onClick={handleEmployeeRemove}>
              {t("employees.delete")}
            </Button>
          </EmployeeForm>
        ) : (
          <Navigate to="/employees" />
        )}
      </Transition>
      <ConfirmationDialog dialogState={confirmationDialogState} />
    </>
  );
};
