import { ArrowRightIcon } from "@heroicons/react/24/outline";

import { Button } from "@/components/ui/Button";
import { useFlag } from "@/features/feature-flags/providers/FlagsContext";
import { some } from "@/utils/utils";

import { usePaymentsStore } from "../hooks/use-payments-store";

type FreeTrialSectionProps = {
  heading: string;
  description: string;
  to?: string;
  buttonText: string;
  onClick?: () => void;
};

export const SubscriptionCTA = ({
  heading,
  description,
  to,
  buttonText,
  onClick,
}: FreeTrialSectionProps) => {
  const { loading } = usePaymentsStore();
  const { enabled: paymentsEnabled } = useFlag("in_app_payments");

  if (!paymentsEnabled) {
    return null;
  }

  return (
    <section className="mb-8 rounded-lg bg-white shadow-lg shadow-stone-900/5">
      <div className="flex flex-col items-center justify-between gap-4 px-4 py-6 sm:px-8">
        <div className="grid gap-4">
          <h2 className="text-center text-2xl font-bold tracking-tight text-stone-700 lg:text-left">
            <span className="block">{heading}</span>
          </h2>
          <p className="py-3 text-center leading-6 text-stone-500 lg:text-left">{description}</p>
        </div>
        <div className="self-center lg:self-start">
          <Button
            {...(to ? { to } : { onClick })}
            endIcon={<ArrowRightIcon />}
            size="medium"
            loading={some(loading)}>
            {buttonText}
          </Button>
        </div>
      </div>
    </section>
  );
};
