import { useTranslation } from "react-i18next";
import type { To } from "react-router-dom";

import { ArrowLeftIcon, PlusCircleIcon, ArrowsUpDownIcon } from "@heroicons/react/24/outline";

import { Button } from "@components/ui/Button";

import { useBreakpoint } from "@hooks/use-breakpoint";

import { useSessionStore } from "@/features/session/hooks";

type ServiceEntitiesHeaderProps = {
  title: string;
  backUrl?: To;
  handleAdd: () => void;
  handleOrder?: () => void;
  isDraggable?: boolean;
  isDnDVisible?: boolean;
  handleSaveOrder?: () => void;
};

export const ServiceEntitiesHeader = ({
  title,
  backUrl,
  handleAdd,
  handleOrder,
  isDraggable,
  isDnDVisible = true,
  handleSaveOrder,
}: ServiceEntitiesHeaderProps) => {
  const { t } = useTranslation();
  const { isMd } = useBreakpoint("md");
  const { permissions } = useSessionStore();

  return (
    <div className="justify-left sticky top-0 flex items-center gap-4 rounded-t-lg bg-stone-50 px-1 py-2 shadow-sm">
      {isMd ||
        (backUrl && (
          <Button variant="neutral" to={backUrl}>
            <ArrowLeftIcon className="h-6 w-6 text-stone-400" />
          </Button>
        ))}
      <h4 className="text-lg font-semibold text-stone-600">{title}</h4>
      <Button
        size="small"
        variant="primary-inline"
        disabled={!permissions.manage_treatments}
        startIcon={<PlusCircleIcon />}
        onClick={handleAdd}>
        {t("generic.addNew")}
      </Button>
      {isDnDVisible &&
        permissions.manage_treatments &&
        (!isDraggable ? (
          <>
            {handleOrder && (
              <Button
                size="small"
                variant="primary-inline"
                onClick={handleOrder}
                startIcon={<ArrowsUpDownIcon />}>
                {t("generic.changeOrder")}
              </Button>
            )}
          </>
        ) : (
          <>
            {handleSaveOrder && (
              <Button size="small" variant="primary" onClick={handleSaveOrder}>
                {t("generic.saveOrder")}
              </Button>
            )}
          </>
        ))}
    </div>
  );
};
