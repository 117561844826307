import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { RadioGroup } from "@headlessui/react";

import { CheckIcon, PlusIcon } from "@heroicons/react/24/outline";

import { formatPrice } from "@features/payments/utils";

import { DefaultDialog } from "@components/dialogs/DefaultDialog";
import { Button } from "@components/ui/Button";
import { Spinner } from "@components/ui/Spinner";

import { useToasts } from "@providers/ToastsProvider";

import { usePaymentsStore } from "@features/payments/hooks";
import { useSessionStore } from "@features/session/hooks";

import { PaymentMode } from "@/types";
import { cn, some } from "@/utils/utils";

type GetSmsPackageFormProps = {
  onClose: () => void;
};

type GetSmsPackageDialogProps = GetSmsPackageFormProps & {
  open: boolean;
};

export const GetSmsPackageDialog = ({ open, onClose }: GetSmsPackageDialogProps) => {
  const { t } = useTranslation();

  return (
    <DefaultDialog open={open} onClose={onClose} title={t("sms.topUpTitle")}>
      <GetSmsPackageForm onClose={onClose} />
    </DefaultDialog>
  );
};

const GetSmsPackageForm = ({ onClose }: GetSmsPackageFormProps) => {
  const { t } = useTranslation();
  const { showToast } = useToasts();
  const { session } = useSessionStore();
  const { loading, smsProducts, fetchProducts, makeOrder, makePayment } = usePaymentsStore();
  const [priceId, setPriceId] = useState<string>();

  const handleSubmit = async () => {
    const result = await makeOrder({
      orderItems: JSON.stringify([{ price: priceId, quantity: 1 }]),
      paymentMode: "payment",
    });

    if (result.errors) {
      return showToast({
        type: "error",
        title: t("generic.oops"),
        description: t("generic.somethingWentWrong"),
      });
    }

    const { data, errors } = await makePayment({
      accountUuid: session?.accountUuid,
      orderItems: result.data?.makeOrder?.orderItems,
      orderUuid: result.data?.makeOrder?.uuid,
      paymentMode: PaymentMode.Payment,
    });

    if (errors) {
      return showToast({
        type: "error",
        title: t("generic.oops"),
        description: t("generic.somethingWentWrong"),
      });
    } else if (data?.makePayment?.url) {
      window.location.href = data.makePayment.url;
    }
    onClose();
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  if (some(loading)) {
    return (
      <div className="flex justify-center p-10">
        <Spinner className="h-8 w-8" />
      </div>
    );
  }

  return (
    <div className="space-y-4">
      <p className="text-center text-xs text-stone-500">{t("sms.topUpDescription")}</p>
      <RadioGroup value={priceId} onChange={setPriceId} className="flex flex-col gap-2">
        {smsProducts?.map((product) => (
          <RadioGroup.Option
            value={product?.priceId}
            key={product?.name}
            className={({ checked }) =>
              cn(
                "relative flex cursor-pointer rounded-sm border px-3 py-1 shadow-md transition-colors focus:outline-none",
                checked ? "border-gold-500 text-gold-500" : "border-stone-300 text-stone-400",
              )
            }>
            {({ checked }) => (
              <RadioGroup.Label as="div" className="w-full divide-y">
                <div className="flex items-center gap-2 py-2 font-medium">
                  {checked ? <CheckIcon className="h-6 w-6" /> : <PlusIcon className="h-6 w-6" />}
                  {product?.name}
                </div>
                {/* TODO: get rid of this check once Product & Price fragment fields will not be optional */}
                {product?.price?.price && product.price.currency && (
                  <div className="py-2 text-xs">
                    {t("sms.buyForPrice", {
                      price: formatPrice({
                        price: product.price.price,
                        currency: product.price.currency,
                      }),
                    })}
                  </div>
                )}
              </RadioGroup.Label>
            )}
          </RadioGroup.Option>
        ))}
      </RadioGroup>
      <div className="align-center mt-4 flex flex-col">
        <Button
          className="mt-4"
          fullWidth
          disabled={!priceId || loading.loadingMakeOrder}
          onClick={handleSubmit}>
          {t("sms.buySms")}
        </Button>
      </div>
    </div>
  );
};
