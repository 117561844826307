import { useTranslation } from "react-i18next";

import { PlusCircleIcon } from "@heroicons/react/24/outline";

import { Subheader } from "@components/layout/subheader/Subheader";
import { Button } from "@components/ui/Button";

import { bindDialogState, useDialog } from "@hooks/use-dialog";

import { useSessionStore } from "@features/session/hooks";

import { AddEmployeeDialog } from "./AddEmployeeDialog";

export const EmployeeListSubheader = () => {
  const { t } = useTranslation();
  const { isSalonPlan, permissions } = useSessionStore();
  const dialogState = useDialog();

  return (
    <div className="mb-2 sm:mb-8">
      <Subheader
        title={t("employees.employees")}
        leftSlot={
          permissions.add_employee ? (
            <Button
              startIcon={<PlusCircleIcon />}
              size="small"
              onClick={() => dialogState.open()}
              disabled={!isSalonPlan}>
              {t("employees.addNew")}
            </Button>
          ) : null
        }
      />
      <AddEmployeeDialog {...bindDialogState(dialogState)} />
    </div>
  );
};
