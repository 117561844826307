import { ReactNode, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { DndContext, closestCorners, DragEndEvent } from "@dnd-kit/core";
import {
  restrictToWindowEdges,
  restrictToVerticalAxis,
  restrictToParentElement,
} from "@dnd-kit/modifiers";
import { SortableContext, arrayMove, verticalListSortingStrategy } from "@dnd-kit/sortable";

import { Button } from "@components/ui/Button";
import { Spinner } from "@components/ui/Spinner";

import { useTreatmentsStore } from "@features/services/hooks";

import { TreatmentListItem } from "@/features/services/components/treatments/TreatmentListItem";
import { Treatment } from "@/types";

type TreatmentListWrapperProps = {
  noResultText: string;
  renderTreatment: (treatment: Treatment) => ReactNode;
  onEdit?: (treatment: Treatment) => void;
  onRemove?: (treatment: Treatment) => void;
  isDraggable?: boolean;
  categoryUuid?: string;
  setTempTreatments: (treatments: Treatment[]) => void;
  treatments: Treatment[];
};

export const TreatmentListWrapper = ({
  noResultText,
  renderTreatment,
  onEdit,
  onRemove,
  isDraggable = false,
  categoryUuid,
  setTempTreatments,
  treatments,
}: TreatmentListWrapperProps) => {
  const { t } = useTranslation();
  const { loading, loadingFetchMore, hasMore, fetchMore, count } = useTreatmentsStore(categoryUuid);

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;

      if (over && active.id !== over.id) {
        const activeIndex = treatments.findIndex(
          (treatment: Treatment) => treatment.uuid === active.id,
        );
        const overIndex = treatments.findIndex(
          (treatment: Treatment) => treatment.uuid === over.id,
        );
        const newEntities = arrayMove(treatments, activeIndex, overIndex);

        setTempTreatments(newEntities);
      }
    },
    [treatments, setTempTreatments],
  );

  const handleFetchMore = useCallback(() => {
    fetchMore()?.then((data) => {
      const fetchedTreatments = data.data.fetchTreatments.edges.map(
        (edge: { node: Treatment }) => edge.node,
      );
      const newEntities = [...treatments, ...fetchedTreatments];
      setTempTreatments(newEntities);
    });
  }, [fetchMore, treatments, setTempTreatments]);

  if (loading) {
    return (
      <div className="flex justify-center p-10">
        <Spinner className="h-8 w-8" />
      </div>
    );
  }

  if (treatments.length === 0) {
    return (
      <span className="flex w-full items-center justify-center py-10 text-stone-500">
        {noResultText}
      </span>
    );
  }

  return (
    <DndContext
      collisionDetection={closestCorners}
      onDragEnd={handleDragEnd}
      modifiers={[restrictToVerticalAxis, restrictToWindowEdges, restrictToParentElement]}>
      <ul className="divide-y">
        <SortableContext
          items={treatments.map((treatment: Treatment) => treatment.uuid)}
          strategy={verticalListSortingStrategy}>
          {treatments.map((treatment: Treatment) => (
            <TreatmentListItem
              key={treatment.uuid}
              treatment={treatment as Treatment}
              renderTreatment={renderTreatment as (treatment: Treatment) => ReactNode}
              onEdit={onEdit as (treatment: Treatment) => void}
              onRemove={onRemove as (treatment: Treatment) => void}
              isDraggable={isDraggable}
            />
          ))}
          {hasMore && treatments.length < count && !loadingFetchMore && (
            <li className="py-4">
              <Button onClick={handleFetchMore} size="small" className="mx-auto">
                {t("generic.loadMore")}
              </Button>
            </li>
          )}
          {loadingFetchMore && (
            <li className="flex justify-center py-4">
              <Spinner className="h-8 w-8" />
            </li>
          )}
        </SortableContext>
      </ul>
    </DndContext>
  );
};
