import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { ChatBubbleLeftEllipsisIcon, PencilIcon, PhoneIcon } from "@heroicons/react/24/solid";

import { Button } from "@/components/ui/Button";
import { ClientTags } from "@/features/clients/components/ClientTags";
import { SendSMSDialog } from "@/features/messages/components/SendSMSDialog";
import { useSessionStore } from "@/features/session/hooks";
import { Client } from "@/types";

type ClientProfileHeaderProps = {
  client: Client;
};

export const ClientProfileHeader = ({ client }: ClientProfileHeaderProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { permissions } = useSessionStore();
  const [open, setOpen] = useState(false);

  return (
    <div className="w-full">
      <div className="mt-8 flex @container ">
        <div className="flex w-full flex-wrap items-center pb-1 @[480px]:flex-nowrap @[480px]:items-center @[480px]:justify-between">
          <div className="flex min-w-0 flex-col">
            <ClientTags tags={client.tags} />
            <div className="flex">
              <h1 className="mt-1 text-2xl font-bold text-stone-900 @[480px]:w-max">
                {client.fullName}
              </h1>
            </div>
          </div>
          <div className="mt-2 flex w-full flex-row justify-stretch space-x-3 self-end @[480px]:ml-4 @[480px]:mt-0 @[480px]:w-auto">
            <Button
              size="small"
              variant="secondary-outline"
              className="mt-3 gap-x-1.5 @[480px]:w-fit"
              disabled={!permissions.send_sms}
              onClick={() => setOpen(true)}>
              <ChatBubbleLeftEllipsisIcon
                className="-ml-0.5 h-4 w-4 text-stone-500 transition-colors hover:text-stone-600"
                aria-hidden="true"
              />
              {t("generic.sms")}
            </Button>
            <Button
              size="small"
              variant="secondary-outline"
              className="mt-3 gap-x-1.5 @[480px]:w-fit"
              disabled={!client.phone || !permissions.client_details_view}
              to={
                client.phone && permissions.client_details_view ? `tel:${client.phone}` : undefined
              }>
              <PhoneIcon
                className="-ml-0.5 h-4 w-4 text-stone-500 transition-colors hover:text-stone-600"
                aria-hidden="true"
              />
              {t("generic.call")}
            </Button>
            <Button
              size="small"
              variant="secondary-outline"
              className="mt-3 gap-x-1.5 @[480px]:w-fit"
              onClick={() => navigate(`/clients/${client.uuid}/edit`)}
              disabled={!permissions.edit_client}>
              <PencilIcon
                className="-ml-0.5 h-4 w-4 text-stone-500 transition-colors hover:text-stone-600"
                aria-hidden="true"
              />
              {t("generic.edit")}
            </Button>
          </div>
        </div>
      </div>
      <SendSMSDialog open={open} setOpen={setOpen} client={client} />
    </div>
  );
};
