import {
  ApolloClient,
  ApolloProvider as ApolloClientProviderProps,
  ApolloLink,
  createHttpLink,
  from,
  NormalizedCacheObject,
  ServerError,
  split,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { getMainDefinition } from "@apollo/client/utilities";
import { ReactNode, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";

import * as AbsintheSocket from "@absinthe/socket";
import { AbsintheSocketLink, createAbsintheSocketLink } from "@absinthe/socket-apollo-link";
import { useAuth0 } from "@auth0/auth0-react";
import { Socket } from "phoenix";

import { useToasts } from "@providers/ToastsProvider";

import { useRouterUtils } from "@/features/router/hooks/use-router-utils";
import { getCachePolicy } from "@/lib/apollo-cache-policy";

import { useSyncSessionContext } from "./SyncSessionProvider";

const { VITE_API_WS_URL, VITE_API_URL } = import.meta.env;

type ApolloClientProviderProps = {
  children: ReactNode;
};

const getWebSocketLink = (token: string) => {
  const phoenixSocket = new Socket(VITE_API_WS_URL, {
    params: {
      token: token,
    },
    heartbeatIntervalMs: 10000,
  });

  const absintheSocket = AbsintheSocket.create(phoenixSocket);
  const webSocketLink = createAbsintheSocketLink(absintheSocket);

  return webSocketLink;
};

const getSplitLink = (webSocketLink: AbsintheSocketLink, httpLink: ApolloLink) => {
  return split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === "OperationDefinition" && definition.operation === "subscription";
    },
    // @ts-expect-error - wrong library types
    webSocketLink,
    httpLink,
  );
};

export const ApolloClientProvider = ({ children }: ApolloClientProviderProps) => {
  const { isOnEmailVerificationPage, isOnOnboardingPage, isOnMaintenancePage, isInitial } =
    useRouterUtils();

  const shouldDisableWebSockets =
    isOnEmailVerificationPage || isOnOnboardingPage || isOnMaintenancePage || isInitial;

  const { logout } = useAuth0();
  const { token } = useSyncSessionContext();
  const { t } = useTranslation();
  const { showToast } = useToasts();
  const apolloClientRef = useRef<ApolloClient<NormalizedCacheObject>>();

  const httpLink = useMemo(
    () =>
      createHttpLink({
        uri: VITE_API_URL,
        headers: {
          authorization: `Bearer ${token}`,
        },
      }),
    [VITE_API_URL, token],
  );

  const splitLink = useMemo(
    () => (shouldDisableWebSockets ? httpLink : getSplitLink(getWebSocketLink(token), httpLink)),
    [httpLink, shouldDisableWebSockets],
  );

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      for (const error of graphQLErrors) {
        switch (error.message) {
          case "employee_not_found":
            showToast({
              type: "error",
              title: t("employees.errors.employee_not_found.title"),
              description: t("employees.errors.employee_not_found.description"),
            });
            break;
          case "not_authenticated":
            logout();
            apolloClientRef.current?.cache.reset();
            break;
          default:
            console.log(error);
            showToast({
              type: "error",
              title: t("generic.oops"),
              description: t("generic.somethingWentWrong"),
            });
            break;
        }
      }
    }

    if (networkError) {
      if (networkError?.name === "ServerError") {
        // @ts-expect-error - wrong library types
        switch ((networkError as ServerError)?.result?.message) {
          case "user_not_found":
            logout();
            apolloClientRef.current?.cache.reset();
            break;
          default:
            console.error(networkError);
            break;
        }
      } else {
        console.error(networkError);
      }
    }
  });

  if (!apolloClientRef.current) {
    apolloClientRef.current = new ApolloClient({
      link: from([errorLink, splitLink]),
      cache: getCachePolicy(),
      defaultOptions: {
        watchQuery: {
          fetchPolicy: "cache-first",
          partialRefetch: true,
          errorPolicy: "all",
        },
        query: {
          fetchPolicy: "cache-first",
          partialRefetch: true,
          errorPolicy: "all",
        },
        mutate: {
          errorPolicy: "all",
        },
      },
      connectToDevTools: process.env.NODE_ENV !== "production",
    });
  }

  return (
    <ApolloClientProviderProps client={apolloClientRef.current}>
      {children}
    </ApolloClientProviderProps>
  );
};
