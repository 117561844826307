import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import { Listbox } from "@headlessui/react";

import { CLIENTS_FETCH_LIMIT } from "@features/clients/hooks";

import { Button } from "@/components/ui/Button";
import { SelectSearch } from "@/components/ui/Select/SelectSearch";
import { Spinner } from "@/components/ui/Spinner";
import { useSessionStore } from "@/features/session/hooks";
import { Option } from "@/utils/select-utils";
import { cn } from "@/utils/utils";

type ClientSelectOptionsProps = {
  options: Option[];
  hasMore: boolean;
  loadingFetchMore: boolean;
  allClientsCount: number;
  addClient: () => void;
  fetchMore: (limit?: number) => void;
  onSearch?: (query: string) => void;
  disableSearch?: boolean;
  selected: string;
};

export const ClientSelectOptions = ({
  options,
  selected,
  addClient,
  allClientsCount,
  hasMore,
  loadingFetchMore,
  fetchMore,
  onSearch,
  disableSearch = false,
}: ClientSelectOptionsProps) => {
  const { t } = useTranslation();
  const { permissions } = useSessionStore();

  const clientsLeftCount = allClientsCount - options.length;
  const isOptionSelected = (option: Option) => option.value === selected;

  return (
    <Listbox.Options className="mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-sm shadow-lg ring-1 ring-stone-300 focus:outline-none">
      {!disableSearch && onSearch && (
        <SelectSearch
          name="clientUuid"
          placeholder={t("clients.searchPlaceholder")}
          onSearch={onSearch}
          noResult={options.length === 0}
        />
      )}
      <div className="flex flex-1 select-none justify-start bg-stone-100 p-3">
        <Button
          size="tiny"
          variant="secondary-outline"
          onClick={addClient}
          disabled={!permissions.add_client}>
          {t("clients.addNew")}
        </Button>
      </div>
      {options.map((option: Option) => (
        <Listbox.Option key={option.value} value={option.value} as={Fragment}>
          {({ active }) => (
            <li
              className={cn(
                isOptionSelected(option) && "bg-stone-100",
                "relative cursor-pointer select-none px-4 py-2 text-stone-600",
                {
                  "bg-stone-50 text-stone-700": active && !isOptionSelected(option),
                },
              )}>
              {option.label}
            </li>
          )}
        </Listbox.Option>
      ))}
      {hasMore && !loadingFetchMore && (
        <div className="flex flex-wrap justify-start gap-2 bg-stone-100 px-3 py-4">
          <Button onClick={() => fetchMore()} variant="primary" size="tiny" className="grow">
            {t("actions.loadMore", {
              count:
                CLIENTS_FETCH_LIMIT > clientsLeftCount ? clientsLeftCount : CLIENTS_FETCH_LIMIT,
            })}
          </Button>
          <Button
            onClick={() => fetchMore(clientsLeftCount)}
            variant="primary-outline"
            size="tiny"
            className="grow">
            {t("actions.loadAll", {
              count: clientsLeftCount,
            })}
          </Button>
        </div>
      )}
      {loadingFetchMore && (
        <div className="flex justify-center py-4">
          <Spinner className="h-8 w-8" />
        </div>
      )}
    </Listbox.Options>
  );
};
