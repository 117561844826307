import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { ArrowRightIcon } from "@heroicons/react/24/outline";

import { DATE_FORMAT } from "@constants/date-formats";
import format from "date-fns/format";

import { Button } from "@components/ui/Button";

import { useCountersStore } from "@features/session/hooks";

import { Spinner } from "@/components/ui/Spinner";
import { useFlag } from "@/features/feature-flags/providers/FlagsContext";
import { sendEvent } from "@/utils/google-analytics";
import { cn } from "@/utils/utils";

import { DANGER_LIMIT, LIMIT_PER_MONTH, WARNING_LIMIT } from "../constants";

export const AppointmentsLimitBar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { remainingAppointments, loadingRemainingAppointments } = useCountersStore();
  const { enabled: paymentsEnabled } = useFlag("in_app_payments");

  const indicatorWidth = useMemo(
    () => `${(remainingAppointments / LIMIT_PER_MONTH) * 100}%`,
    [remainingAppointments],
  );

  return loadingRemainingAppointments ? (
    <div className="py-8">
      <Spinner />
    </div>
  ) : (
    <div className="flex w-full max-w-full flex-col items-start rounded-sm bg-stone-50 px-4 py-3 shadow-sm">
      <div
        className={cn("relative flex h-6 w-full items-center justify-center rounded-sm border ", {
          "border-red-500 bg-red-400": remainingAppointments <= DANGER_LIMIT,
          "border-orange-500 bg-orange-400":
            remainingAppointments > DANGER_LIMIT && remainingAppointments <= WARNING_LIMIT,
          "border-gold-500 bg-gold-400": remainingAppointments > WARNING_LIMIT,
        })}>
        <span
          className={cn("z-10 text-xs capitalize", {
            "text-red-100": remainingAppointments <= DANGER_LIMIT,
            "text-orange-100":
              remainingAppointments > DANGER_LIMIT && remainingAppointments <= WARNING_LIMIT,
            "text-gold-100": remainingAppointments > WARNING_LIMIT,
          })}>
          {format(new Date(), DATE_FORMAT.MONTH_STAND_ALONE)}
        </span>
        <div
          className={cn("width-0 absolute inset-y-0 left-0 transition-[width,_background-color]", {
            "bg-red-500": remainingAppointments <= DANGER_LIMIT,
            "bg-orange-500":
              remainingAppointments > DANGER_LIMIT && remainingAppointments <= WARNING_LIMIT,
            "bg-gold-500": remainingAppointments > WARNING_LIMIT,
          })}
          style={{ width: indicatorWidth }}></div>
      </div>
      <span className="py-2 text-sm text-stone-500">
        {t("appointments.amountLeft", { count: remainingAppointments })}
      </span>
      {paymentsEnabled && (
        <Button
          variant="neutral"
          className="mt-2 flex items-center self-end text-sm text-gold-500"
          endIcon={<ArrowRightIcon className="-mr-0.5 h-4 w-4" aria-hidden="true" />}
          onClick={() => {
            sendEvent("upgrade_cta", "payments", "appointments_limit_bar");
            navigate("/subscriptions");
          }}>
          {t("header.salonMenu.removeLimits")}
        </Button>
      )}
    </div>
  );
};
