import { useTranslation } from "react-i18next";

import { Layout } from "@components/layout/Layout";

import { EmployeeList } from "@features/employees/components/EmployeeList";
import { EmployeeListSubheader } from "@features/employees/components/EmployeeListSubheader";

import { useSessionStore } from "@features/session/hooks";

import { sendEvent } from "@//utils/google-analytics";
import { SubscriptionCTA } from "@/features/payments/components/SubscriptionCTA";
import { useProductsStore } from "@/features/payments/hooks/use-products-store";

export const EmployeesView = () => {
  const { t } = useTranslation();
  const { isSalonPlan, trialUsed } = useSessionStore();
  const { orderSalonPlan } = useProductsStore();

  return (
    <Layout title={t("employees.employees")}>
      <div className="-ml-4 -mr-4 mt-4 max-w-6xl rounded-xl bg-stone-50 px-4 py-8 sm:mt-8 sm:px-12 sm:py-12 md:mx-auto md:px-12">
        {isSalonPlan ? null : trialUsed ? (
          <div className="w-full md:mx-auto">
            <SubscriptionCTA
              to="/subscriptions"
              heading={t("subscriptions.cta.employees.trial.heading")}
              description={t("subscriptions.cta.employees.upgrade.description")}
              buttonText={t("subscriptions.cta.generic.seeMore")}
              onClick={() => sendEvent("upgrade_see_more", "payments", "employees")}
            />
          </div>
        ) : (
          <div className="w-full md:mx-auto">
            <SubscriptionCTA
              heading={t("subscriptions.cta.employees.trial.heading")}
              description={t("subscriptions.cta.employees.trial.description")}
              buttonText={t("subscriptions.cta.generic.startTrial")}
              onClick={() => {
                sendEvent("free_trial_click", "payments", "employees");
                orderSalonPlan();
              }}
            />
          </div>
        )}

        <EmployeeListSubheader />
        <EmployeeList />
      </div>
    </Layout>
  );
};
