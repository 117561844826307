import { Navigate, Outlet } from "react-router-dom";

import { useAuth0 } from "@auth0/auth0-react";

import { Spinner } from "@components/ui/Spinner";

import { useSessionStore } from "@features/session/hooks";

import { ROUTES } from "@/features/router/constants/routes";
import { useRouterUtils } from "@/features/router/hooks/use-router-utils";

export const AuthenticatedRoutes = () => {
  const { session, loading, isOnboarded, isEmailVerified } = useSessionStore();
  const { isAuthenticated } = useAuth0();

  const { isOnOnboardingPage, isOnMaintenancePage, isOnEmailVerificationPage } = useRouterUtils();

  if (loading) {
    return (
      <div className="absolute inset-1/2 h-[15vh] w-[15vh] -translate-x-1/2 -translate-y-1/2">
        <Spinner className="h-12 w-12 sm:h-16 sm:w-16 lg:h-24 lg:w-24" />
      </div>
    );
  }

  if (!isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  if (!isEmailVerified && !isOnEmailVerificationPage) {
    return <Navigate to="/email-not-verified" replace />;
  }

  if (isAuthenticated && !!session && !isOnboarded && !isOnOnboardingPage) {
    return <Navigate to={ROUTES.ONBOARDING_PERSONAL} replace />;
  }

  if (isAuthenticated && !session && isEmailVerified && !isOnMaintenancePage) {
    return <Navigate to={ROUTES.MAINTENANCE} replace />;
  }

  return <Outlet />;
};
