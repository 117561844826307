import { useTranslation } from "react-i18next";

import { useSessionStore } from "@/features/session/hooks";
import { Client } from "@/types";

type ClientInformationProps = {
  client: Client;
};

export const ClientInformation = ({ client }: ClientInformationProps) => {
  const { t } = useTranslation();
  const { permissions } = useSessionStore();

  return (
    <div className="mx-auto mt-6 max-w-5xl px-4 @container sm:px-6 lg:px-8">
      <div className="grid grid-cols-1 gap-x-4 gap-y-8 @[480px]:grid-cols-2">
        {permissions.client_details_view && (
          <>
            <div className="@[480px]:col-span-1">
              <div className="mb-2 text-sm font-medium text-stone-500">
                {t("generic.contactDetails")}
              </div>
              {client.phone || client.email ? (
                <>
                  {client.phone && (
                    <div className="mt-1 text-sm text-stone-900">{client.phone}</div>
                  )}
                  {client.email && (
                    <div className="mt-1 text-sm text-stone-900">{client.email}</div>
                  )}
                </>
              ) : (
                <div className="mt-1 text-sm text-stone-900">{t("generic.noData")}</div>
              )}
            </div>
            <div className="@[480px]:col-span-1">
              <div className="mb-2 text-sm font-medium text-stone-500">{t("generic.address")}</div>
              <div className="mt-1 text-sm text-stone-900">
                {client.street && client.streetNumber
                  ? `${client.street} ${client.streetNumber}`
                  : "Brak"}
              </div>
              {client.postalCode && client.city && (
                <div className="mt-1 text-sm text-stone-900">{`${client.postalCode} ${client.city}`}</div>
              )}
            </div>
          </>
        )}
        <div className="@[480px]:col-span-2">
          <div className="mb-2 text-sm font-medium text-stone-500">{t("clients.clientNote")}</div>
          <div className="mt-1 max-w-prose space-y-5 text-sm text-stone-900">
            {client.note || t("clients.clientNoteEmpty")}
          </div>
        </div>
      </div>
    </div>
  );
};
